import { AnyAction, ReducersMapObject, ThunkMiddleware, configureStore } from "@reduxjs/toolkit";
import { StateSchema } from "../types/stateSchema";
import { createReducerManager } from "./reducerManager";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { api } from "@Shared/api/createAxiosApi";
import { UserReducer } from "@Entities/User";
import { WindowReducer } from "@Entities/Window";
import { FormReducer } from "@Entities/Form";
import { NextRouter } from "next/router";
import { AchievementsReducer } from "@Features/AchievementsForm/model/slice/achievementsSlice";
import { MasterExamsReducer } from "@Features/ExamsForm/MasterExamsForm/slice/masterExamsSlice";
import { notificationReducer } from "@Entities/Notification/model/slice/notificationSlice";
import { loaderReducer } from "@Entities/Loader/model/slice/loaderSlice";
import { dictionariesApi } from "@/store/rtk/dictionariesApi";

export interface StoreOptions {
  initialState?: StateSchema
  router?: NextRouter
}

export const createReduxStore = (options: StoreOptions): ToolkitStore<StateSchema, AnyAction, [ThunkMiddleware<StateSchema, AnyAction>]> => {
  const { router, initialState } = options;

  const rootReducers: ReducersMapObject<StateSchema> = {
    user: UserReducer,
    window: WindowReducer,
    form: FormReducer,
    achievements: AchievementsReducer,
    masterExams: MasterExamsReducer,
    notification: notificationReducer,
    loader: loaderReducer,

    [dictionariesApi.reducerPath]: dictionariesApi.reducer,
  };

  const reducerManager = createReducerManager(rootReducers);

  const store = configureStore<StateSchema>({
    preloadedState: initialState,
    reducer: reducerManager.reduce,
    // @ts-expect-error
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            api: api,
            router,
          },
        },
      }).concat(dictionariesApi.middleware),
  });
  // @ts-expect-error
  store.reducerManager = reducerManager;
  // @ts-expect-error
  return store;
};
