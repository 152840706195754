import { currentCompanyYear } from "@/lib/configTest";
import DegreeEnum from "@/lib/enums/degree";
import UserRoleEnum, { windowsEnum } from "@/lib/enums/userRole";
import { getAvailableDegreesByWindow } from "@/lib/getAvailableDegrees";
import { StateSchema } from "@App/providers/StoreProvider";
import { ThemeEnum, ThemeTypes } from "@Shared/types/themeTypes";

export const getUserData = (state: StateSchema): IUserData => {
  return state.user.user;
};

export const getUserAcceptence = (state: StateSchema): boolean => {
  return state.user.acceptance || false;
};

export const getUserInited = (state: StateSchema): boolean => {
  return state.user._isInited;
};

export const getUserRoles = (state: StateSchema): UserRoleEnum[] => {
  return state.user.user.roles || [];
};

export const getUserConfiguration = (state: StateSchema): any => {
  return {
    acceptance: state.user.acceptance,
    year: state.user.year,
    changeAchievement: state.user.changeAchievement,
  };
};

export const getUserId = (state: StateSchema): number => {
  return state?.user?.user?.id;
};

export const getUserTheme = (state: StateSchema): ThemeTypes => {
  return state.user?.theme || ThemeEnum.NORMAL;
};

export const getUserAccessableSettings = (
  state: StateSchema
): IAccessibleSettings => {
  return state.user.accessibleSettings;
};

export const getStudentDetailsUserId = (state: StateSchema): number => {
  return state?.user?.user?.student_details;
};

export const getUserDegree = (state: StateSchema): IDegree => {
  return (
    state?.user?.user?.degree ||
    state?.user?.studentDetailActive?.degree ||
    state.window?.questionnaire?.studentDetails?.degree
  );
};

export const getAvailableDegrees = (state: StateSchema): IDegree[] => {
  const availableDegrees = getAvailableDegreesByWindow(
    state?.user?.user?.windows ?? []
  );

  if (!!availableDegrees.length) {
    return availableDegrees;
  }

  const degreeSet = new Set(state.user.user.manager_degrees);
  for (const degree of [
    DegreeEnum.BACHELOR,
    DegreeEnum.MASTER,
    DegreeEnum.POSTGRADUATE,
  ]) {
    if (degreeSet.has(degree)) {
      availableDegrees.push(degree);
    }
  }

  return availableDegrees;
};

export const getIsForeignerAllowed = (state: StateSchema): boolean => {
  return state?.user?.user?.windows.some(
    ({ role }) => role === windowsEnum.ROLE_WINDOW_FOREIGNER
  );
};

export const getUserYear = (state: StateSchema): number => {
  return state.user.year || currentCompanyYear;
};

export const getUserStudentDetailsLocked = (state: StateSchema): boolean => {
  return state?.user?.studentDetailActive?.locked || false;
};

export const getUserCitizenship = (state: StateSchema): string => {
  return state.user.user.citizenship;
};

export const getMasterIsGraduated = (
  state: StateSchema
): boolean | undefined => {
  return state.masterEducationReducer?.isGraduatedFromItmoMaster;
};

export const getPostgraduateIsGraduated = (
  state: StateSchema
): boolean | undefined => {
  return state.postgraduateEducationReducer?.isGraduatedFromItmoPostgraduate;
};
