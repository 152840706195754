import { api } from "@Shared/api/createAxiosApi";
import { captureException } from "@sentry/nextjs";
import { EntranceMasterExamItem, EntranceMasterExamsSchema } from "../../types/formTypes";
import { RequestOptions } from "../../types/modelTypes";

interface MasterExamsResponse {
  result: EntranceMasterExamsSchema
}

export const FetchMasterExamsData = async (options: RequestOptions): Promise<EntranceMasterExamsSchema> => {
  const { userId, studentDetailsId } = options;

  try {
    const url = `/users/${userId}/studentDetails/${studentDetailsId}/forms/master_exams`;
    const response = await api.get<MasterExamsResponse>(url);
    const correctExams = response.data.result.exams.map((exam): EntranceMasterExamItem => ({
      ...exam,
      examId: exam.id,
      file: exam.file ? [exam.file] : [],
      date: exam.date ? `${new Date(exam.date).toLocaleDateString("ru-RU")} ${new Date(exam.date).toLocaleTimeString("ru-RU", { timeZone: "Europe/Moscow" }).substring(0, 5)}` : "",
    }));
    return {
      ...response.data.result,
      exams: correctExams,
    };
  } catch (error) {
    captureException(error);
    return {
      exams: [],
      availableOptions: [],
      programOptions: [],
      programIds: [],
      is_have_exams: false,
      programFinansing: [],
    };
  }
};
