
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import getUserRoleLabel from "@/lib/_common/getUserRoleLabel";
import UserRoleEnum from "@/lib/enums/userRole";
import { windowQuestionnaireRequirements } from "@/store/action/windowActions";
import { UserActions, getUserData, getUserInited, setStudentDetailsActive, setStudentdetails, } from "@Entities/User";
import { WindowActions } from "@Entities/Window";
import { api } from "@Shared/api/createAxiosApi";
import getT from "next-translate/getT";
import useTranslation from "next-translate/useTranslation";
import { AppProps } from "next/app";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Analytics, appEnv } from "@/lib/config";
import { GoogleTagManagerScript, VkAnalytics } from "@/layouts/Metrics";
import { YandexMetricaProvider } from "next-yandex-metrica";
import { Loader } from "@Entities/Loader";
const Main: FC<AppProps> = (props) => {
    const { Component, ...otherProps } = props;
    const { lang } = useTranslation();
    const dispatch = useDispatch();
    const isInited = useSelector(getUserInited);
    const user = useSelector(getUserData);
    const isWindowUser = user?.roles.includes(UserRoleEnum.ROLE_WINDOW) && user.windows.length > 0;
    const isManagerUser = user?.roles.includes(UserRoleEnum.ROLE_MANAGER);
    const isRegularUser = !isWindowUser && !isManagerUser;
    useEffect(() => {
        const initApplication = async () => {
            if (isRegularUser) {
                dispatch(setStudentdetails({ userId: user.id }));
                dispatch(setStudentDetailsActive({ userId: user.id }));
            }
            const isQuestionnairePage = window.location.pathname
                .split("/")
                .includes("questionnaire");
            if (isQuestionnairePage) {
                await windowQuestionnaireRequirements({ dispatch });
            }
            if (isWindowUser) {
                const windowId = user.windows
                    .slice()
                    .sort((a, b) => a.id - b.id)
                    .at(0)?.id;
                const studentDetailsActiveResponse = await api.get(`/users/${user.id}/studentDetails/active`);
                const studentDetailsActive = studentDetailsActiveResponse.data.result;
                dispatch(UserActions.setStudentDetailsActive(studentDetailsActive));
                const activeWindow = user.windows.find((w: any) => w.id === windowId);
                const payload = {
                    id: activeWindow?.id,
                    title: activeWindow?.title,
                    role: activeWindow?.role,
                    label: getUserRoleLabel(activeWindow?.role || UserRoleEnum.ROLE_USER),
                };
                if (activeWindow && !isQuestionnairePage) {
                    await dispatch(WindowActions.setWindow(payload));
                }
            }
            if (isManagerUser) {
                const t = await getT(lang, "common");
                const payload = {
                    id: 0,
                    title: t("manager_name"),
                    role: UserRoleEnum.ROLE_MANAGER,
                    label: getUserRoleLabel(UserRoleEnum.ROLE_MANAGER),
                };
                await dispatch(WindowActions.setWindow(payload));
            }
            if (!isRegularUser) {
                dispatch(UserActions.setInited());
            }
        };
        initApplication();
    }, []);
    if (isInited) {
        return (<>
        {appEnv === "prod" ? (<>
            <GoogleTagManagerScript />
            <VkAnalytics />
            {Analytics.ymid?.length ? (<YandexMetricaProvider tagID={+Analytics.ymid} initParameters={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                    }}>
                <Component {...otherProps}/>
              </YandexMetricaProvider>) : (<Component {...otherProps}/>)}
          </>) : (<Component {...otherProps}/>)}
      </>);
    }
    return <Loader infinite/>;
};
export default Main;

    async function __Next_Translate__getStaticProps__19484bfeb07__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/Main',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19484bfeb07__ as getStaticProps }
  