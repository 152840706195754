import styles from "@/components/_Common/Form/Label/Label.module.scss";
import { Form } from "react-bootstrap";
import { FC, PropsWithChildren, ReactNode } from "react";
import HelpIcon from "@/public/images/symbols/hint.svg";
import ActionIcon from "@/components/_Common/ActionIcon";
import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";

type FormLabelProps = {
  className?: string;
  children: ReactNode;
  required?: boolean;
  hint?: boolean;
  type?: "personally" | "education" | "kronbars";
  position?: "one" | "two" | "three" | "grades";
};

const FormLabel: FC<PropsWithChildren<FormLabelProps>> = (
  props
): JSX.Element => {
  const {
    className,
    children,
    required = false,
    hint = false,
    type,
    position,
    ...others
  } = props;
  const { t } = useTranslation(`personal/${type}`);

  return (
    <Form.Label className={clsx(className && className, "d-block")} {...others}>
      {children}
      {required && <span className={styles.required_mark}>*</span>}
      {hint && (
        <ActionIcon
          hint
          Icon={HelpIcon}
          className="d-xl-none"
          placement="top"
        >
          {position ? t(`hint.${position}`) : t("hint")}
        </ActionIcon>
      )}
    </Form.Label>
  );
};

export default FormLabel;
