import { FC } from "react";
import Script from "next/script";
import { getHtml } from "@/lib/_common/get-html";
import { Analytics } from "@/lib/config";

export const GoogleAnalytics: FC<{ gaid?: string }> = ({
  gaid = Analytics.gtmid,
}) => {
  if (!gaid) return null;
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gaid}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)};gtag("js", new Date());gtag('config','${gaid}');`}
      </Script>
    </>
  );
};

export const GoogleTagManager: FC<{ gtmid?: string }> = ({
  gtmid = Analytics.gtmid,
}) => {
  if (!gtmid) return null;
  return (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmid}');`}
      </Script>
      <noscript
        id="gtmNoScript"
        dangerouslySetInnerHTML={getHtml(
          `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmid}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        )}
      />
    </>
  );
};

export const GoogleTagManagerScript: FC<{ gtmid?: string }> = ({
  gtmid = Analytics.gtmid,
}) => {
  if (!gtmid) return null;
  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmid}');`}
    </Script>
  );
};

export const GoogleTagManagerNoScript: FC<{ gtmid?: string }> = ({
  gtmid = Analytics.gtmid,
}) => {
  if (!gtmid) return null;
  return (
    <noscript
      id="gtmNoScript"
      dangerouslySetInnerHTML={getHtml(
        `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmid}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      )}
    />
  );
};

export const VkAnalytics: FC<{ vkid?: string }> = ({
  vkid = Analytics.vkid,
}) => {
  if (!vkid) return null;
  return (
    <>
      <Script id="vk-analytics" strategy="afterInteractive">
        {`!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("${vkid}"),VK.Retargeting.Hit()},document.head.appendChild(t)}();`}
      </Script>
      <noscript
        id="vkNoScript"
        dangerouslySetInnerHTML={getHtml(
          `<img src="https://vk.com/rtrg?p=${vkid}" style="position:fixed; left:-999px;" alt="" />`
        )}
      />
    </>
  );
};
