export const sendError = (err: string): string => {
  return err === "Ошибка в запросе"
    ? "Проверьте заполненные поля, они не валидны"
    : err;
};

export const localCitizenship = "Россия";
export const belorussianCitizenship = "Беларусь";

export const egeValue = "1"; //'ЕГЭ';

export const examUniversity = "Испытание в вузе";

export const centralTestingValue = "5"; //'Централизованное тестирование';

export const dataFinancing = {
  budget: "Бюджет",
  contract: "Контракт",
  budget_contract: "Бюджет и контракт",
};

export const dataFinancingEn = {
  budget: "Tuition-free",
  contract: "Fee-based",
  budget_contract: "Tuition-free and Fee-based",
};

export const dataFinancingForScrollCards = {
  budget: "бюджетной",
  contract: "контрактной",
};

export const dataLevelsForScrollCards = {
  bachelor: "бакалавриата",
  master: "магистратуры",
  postgraduate: "аспирантуры",
};

export const dataAdmission = {
  general_competition: "Общий конкурс",
  without_entry_tests: "Без вступительных испытаний",
  by_special_quota: "По отдельной квоте",
  by_unusual_quota: "По особой квоте",
  by_target_quota: "По целевой квоте",
  on_state_line: "По государственной линии",
};

export const examTypesWithDate = [
  { label: "Испытание в вузе", value: "2" }, // испытание в вузе
  { label: "Профессиональное вступительное испытание", value: "7" }, //'Профессиональное вступительное испытание',
];

export const disciplineWithMinScores = ["57", "53"];

export const individualValue = "individual";
export const selfValue = "self";
export const materialCapitalValue = "maternal_capital";

export const juridicalValue = "juridical";

export const inputTypeImage = "image";

export const inputTypeDocument = "document";

export const inputTypePortfolio = "portfolio";

export const inputTypeSpecialCondition = "special_condition";

export const inputTypeDiplom = "diplom";

export const higherEducationDiploma = "Диплом о высшем образовании";

export const spoEducation = ["Диплом о среднем профессиональном образовании"];

export const spoEducationExamType = "7"; //'Профессиональное вступительное испытание';

export const disciplineWithField: string[] = [
  "Конкурс «Портфолио» Университета ИТМО",
  "Конкурс лучших научных работ стажировки физического факультета",
  "Конкурс «Речевые технологии и машинное обучение»",
  "Конкурс-хакатон «Прикладной искусственный интеллект»",
  "Конкурс-хакатон «Hack For Your Success»",
  "Конкурс программных и/или аппаратных проектов «SHWare»",
  "Конкурс программных или аппаратных проектов «SHWare»",
  "Конкурс «Шаг навстречу успеху»",
  "Конкурс докладов конференции молодых ученых «Навигация и управление движением»",
  "Конкурс докладов конференции молодых ученых «Навигация и управление движением»",
  "Конкурс именных стипендий физического факультета",
  "Конкурс «Науке нужен ты»",
  "Конкурс \"Науке нужен ты\"",
  "Конкурс-хакатон «Прикладной искусственный интеллект»",
  "Конкурс-хакатон «Hack For Your Success»",
  "Конкурс \"Науке нужен ты\"",
  "Конкурс «Искусство и наука»",
  "Конкурс «Речевые технологии и машинное обучение»",
  "Конкурс-хакатон «Прикладной искусственный интеллект»",
  "Конкурс DH проектов",
  "Конкурс лучших научных работ стажировки физического факультета",
  "Конкурс питчей в области Public Health Sciences",
  "Конкурс «Речевые технологии и машинное обучение»",
];

export const emptyFacultyItem = [
  {
    label: "",
    value: -1,
  },
];

export const emptyDictionaryItem = [
  {
    label: "",
    value: "",
  },
  {
    label: "",
    value: "",
  },
];

export const itmostarsFromWhereOther = "another";
export const dictionaryDisciplinePostgraduateSpecialtyValue = "Специальность";

export const achievementStatuses = [
  "accepted",
  "rejected",
  "null",
];

export const newRegionsList = [
  "респ. Донецкая Народная",
  "респ. Луганская Народная",
  "обл. Запорожская",
  "обл. Херсонская",
];
