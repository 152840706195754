import {
  FC,
  ChangeEvent,
  DragEvent,
  MouseEvent,
  SVGProps,
  useState,
  useCallback,
  useEffect,
  RefObject,
  useRef,
} from "react";
import useTranslation from "next-translate/useTranslation";
import userService from "@/lib/services/user.service";
import useQuestionnaire from "@/hooks/useQuestionnaire";
import { FileCategories, MimeType } from "./types";
import { toFileData } from "./lib";
import { clsx } from "clsx";
import styles from "./InputFile.module.scss";
import Close from "@/public/images/symbols/close.svg";
import FileIcon from "@/public/images/symbols/attachment.svg";

interface InputFileProps {
  id?: string;
  placeholder?: string;
  onChange: (value: string[]) => void;
  value?: string[];
  className?: string;
  label?: string;
  hint?: string;
  Icon?: FC<SVGProps<SVGSVGElement>>;
  category?: FileCategories;
  formats?: MimeType[];
  maxSize?: number;
  maxNumFiles?: number;
  disabled?: boolean;
  required?: boolean;
  showValidationErrors?: boolean;
  hideUploader?: boolean;
  onDeleteFile?: () => void;
  inputRef?:
    | RefObject<HTMLInputElement>
    | ((instance: HTMLInputElement | null) => void)
    | null
    | undefined;
  underlined?: boolean
}

interface InputFileState {
  processedFiles: number;
  dataFiles: FileData[];
  error: string;
}

export const InputFile: FC<InputFileProps> = ({
  id,
  value = [],
  onChange,
  formats = [MimeType.Pdf],
  maxSize = 1,
  maxNumFiles = 1,
  Icon,
  placeholder = "Прикрепите файл",
  category = "fluorography",
  hint,
  className,
  disabled = false,
  label,
  required = false,
  inputRef,
  hideUploader = false,
  showValidationErrors = true,
  onDeleteFile,
  underlined = false,
}) => {
  const { t } = useTranslation("form");
  const [{ processedFiles, dataFiles, error }, setState] =
    useState<InputFileState>({
      processedFiles: value?.length ?? 0,
      dataFiles: [],
      error: "",
    });

  const filesRef = useRef<string[]>([]);

  const {
    studentDetails: { id: studentDetailsId },
  } = useQuestionnaire();

  const setQuantity = useCallback(
    (quantity: number, processedFiles: number): boolean => {
      if (quantity + processedFiles > maxNumFiles) {
        setState((prev) => ({
          ...prev,
          error: t("fileInput.errors.maxNumberError", { number: maxNumFiles }),
        }));
        return false;
      }
      setState((prev) => ({
        ...prev,
        processedFiles: prev.processedFiles + quantity,
        error: "",
      }));
      return true;
    },
    []
  );
  const checkFile = useCallback((file: File): boolean => {
    const size = file.size / (1024 * 1024);
    if (size >= maxSize) {
      setState((prev) => ({
        ...prev,
        error: t("fileInput.errors.maxSizeError", {
          size: size.toFixed(2),
          maxSize,
        }),
      }));
      return false;
    }
    if (!formats.includes(file.type as MimeType)) {
      setState((prev) => ({
        ...prev,
        error: t("fileInput.errors.formatError"),
      }));
      return false;
    }
    return true;
  }, []);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      if (!studentDetailsId) return;

      const filePromises = files.map(async (file) => {
        if (checkFile(file)) {
          const data = new FormData();
          data.append("file", file);
          const response = await userService.uploadFile(
            category,
            studentDetailsId,
            data
          );
          return { ...response, file };
        }
      });

      const newDataFiles = (await Promise.all(filePromises)).filter(
        Boolean
      ) as FileData[];
      const noUploaded = files.length - newDataFiles.length;
      setState((prev) => ({
        ...prev,
        processedFiles: prev.processedFiles - noUploaded,
        dataFiles: [...prev.dataFiles, ...newDataFiles],
      }));

      onChange?.([
        ...filesRef.current,
        ...newDataFiles.map(({ url }) => url),
      ] as string[]);

      filesRef.current = [
        ...filesRef.current,
        ...newDataFiles.map(({ url }) => url),
      ];
    },
    [value]
  );

  const dragHandle = useCallback(
    (e: DragEvent<HTMLDivElement>, processedFiles: number) => {
      e.preventDefault();
      if (!disabled && e.dataTransfer.files) {
        const files = Array.from(e.dataTransfer.files);
        if (!setQuantity(files.length, processedFiles)) return;
        uploadFiles(files);
      }
    },
    [disabled]
  );

  const changeHandle = useCallback(
    (e: ChangeEvent<HTMLInputElement>, processedFiles: number) => {
      if (!disabled && e.target.files) {
        const files = Array.from(e.target.files);
        if (!setQuantity(files.length, processedFiles)) return;
        uploadFiles(files);
      }
    },
    [disabled]
  );

  const removeFile = (e: MouseEvent<HTMLButtonElement>, i: number) => {
    e.preventDefault();
    if (!disabled) {
      onChange(filesRef.current.filter((_, index) => index !== i));
      filesRef.current = filesRef.current.filter((_, index) => index !== i);
      setState((prev) => ({
        ...prev,
        processedFiles: prev.processedFiles - 1,
        dataFiles: prev.dataFiles.filter((_, index) => index !== i),
      }));
    }
    onDeleteFile && onDeleteFile();
  };

  const getFileDataByUrl = async (values: string[]) => {
    const urls = values.filter(
      (item) => !dataFiles.find((file) => file.url === item)
    );

    if (urls.length) {
      const response = await userService.getFileName(urls);
      setState((prev) => ({
        ...prev,
        dataFiles: response.originalNames.map((item) => toFileData(item)),
        processedFiles: response.originalNames.length,
      }));
    }

    if (!values.length) {
      setState((prev) => ({
        ...prev,
        dataFiles: [],
        processedFiles: 0,
      }));
    }
  };

  useEffect(() => {
    if (value?.length !== filesRef?.current?.length) {
      filesRef.current = value;
      getFileDataByUrl(value);
    }
  }, [value.length]);

  return (
    <div
      id={`${id}`}
      className={clsx(
        className,
        underlined ? styles.uploader_underlined : styles.uploader
      )}
      onDragLeave={(e) => e.preventDefault()}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => dragHandle(e, processedFiles)}
    >
      {label && (
        <p className={styles.uploader__label}>
          {label}
          {required && " *"}
        </p>
      )}
      {error && showValidationErrors && (
        <span className={styles.uploader__error}>
          <span>{error}</span>
        </span>
      )}
      {!!processedFiles && (
        <ul className={styles.uploader__list} id={`${id}_list`}>
          {dataFiles.map(({ originalName, url, file }, i) => (
            <li
              className={styles.uploader__wrapper}
              id={`${id}_listItem${i + 1}`}
              key={`${originalName}-${i}`}
            >
              {Icon && (
                <Icon
                  className={clsx(
                    styles.uploader__icon,
                    styles.uploader__icon_file
                  )}
                />
              )}
              <a
                className={styles.uploader__placeholder}
                href={url}
                rel="noreferrer"
                target="_blank"
              >
                {originalName}
                {file && (
                  <span className={styles.uploader__hint}>
                    &nbsp; (
                    {file.size / 1024 > 1024
                      ? `${(file.size / (1024 * 1024)).toFixed(2)} ${t(
                        "size.mb"
                      )}`
                      : `${(file.size / 1024).toFixed(2)} ${t("size.kb")}`}
                    )
                  </span>
                )}
              </a>
              <button
                className={styles.uploader__delete}
                id={`${id}_deleteButton${i + 1}`}
                type="button"
                onClick={(e) => removeFile(e, i)}
              >
                <Close
                  className={clsx(
                    styles.uploader__icon,
                    styles.uploader__icon_del
                  )}
                />
              </button>
            </li>
          ))}
        </ul>
      )}
      {processedFiles < maxNumFiles && (
        <label
          id={`${id}_lable`}
          className={clsx(styles.uploader__wrapper, {
            [styles.uploader__hidden]: hideUploader,
          })}
        >
          <FileIcon
            className={clsx(styles.uploader__icon, styles.uploader__icon_file)}
          />
          <span className={styles.uploader__placeholder}>
            {placeholder}{" "}
            <span className={styles.uploader__hint}>
              ({hint ?? t("fileInput.fileDefaulHint")})
            </span>
          </span>
          <input
            hidden
            multiple
            accept={formats.join(",")}
            disabled={disabled}
            id={`${id}_input`}
            ref={inputRef}
            type="file"
            onChange={(e) => changeHandle(e, processedFiles)}
          />
        </label>
      )}
    </div>
  );
};
