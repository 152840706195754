import getErrorMessage from "@/lib/_common/getErrorMessage";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface PostUserCitizenshipOptions {
    body: {
      country_id: number
    }
}

export const postUserCitizenship = createAsyncThunk<void, PostUserCitizenshipOptions, ThunkApiType<string>>(
  "userSlice/postUserCitizenship",
  async (options, thunkApiType) => {
    const { body } = options;
    const { extra, rejectWithValue } = thunkApiType;

    try {
      await extra.api.patch("/users/citizenship", body);

    } catch ({ response: error }: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
