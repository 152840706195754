import Cookies from "js-cookie";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants/constants";
import { AxiosResponse } from "axios";
import { LoginWithCodeResponse } from "../../types/authTypes";

export const setUserCookies = (res: AxiosResponse<LoginWithCodeResponse>): void => {
  const { access_token, refresh_token, access_token_expires_in, refresh_token_expires_in } = res.data.result;

  Cookies.set(ACCESS_TOKEN_KEY, access_token, {
    expires: new Date(access_token_expires_in),
    secure: true,
    sameSite: "strict",
  });
  Cookies.set(REFRESH_TOKEN_KEY, refresh_token, {
    expires: new Date(refresh_token_expires_in),
    secure: true,
    sameSite: "strict",
  });
};
