import { createAsyncThunk } from "@reduxjs/toolkit";
import { StateSchema, ThunkApiType } from "@App/providers/StoreProvider";
import { PostgraduateExamType } from "../../types/postgraduateExamTypes";
import { postPostgraduateExamsAdapter } from "../../lib/postPostgraduateExamsAdapter";
import { NextRouter } from "next/router";
import { getPostgraduateExamsData } from "../selectors/postgraduateSelectors";
import { nextPage } from "@Entities/Sidebar";
import getErrorMessage from "@/lib/_common/getErrorMessage";
import { notificationActions } from "@Entities/Notification/model/slice/notificationSlice";

export interface PostPostgraduateExams {
  userId: number;
  studentDetailsId: number;
  year: number;
  router: NextRouter;
  isWindow: boolean;
  degree: IDegree;
}

export const postPostgraduateExams = createAsyncThunk<
  PostgraduateExamType[],
  PostPostgraduateExams,
  ThunkApiType<string>
>("postgraduateExams/postPostgraduateExams", async (options, thunkApi) => {
  const { userId, studentDetailsId, year, isWindow } = options;
  const { extra, rejectWithValue, getState, dispatch } = thunkApi;
  const data = getPostgraduateExamsData.selectAll(getState() as StateSchema);
  const payload = { ...data };

  try {
    const body = await postPostgraduateExamsAdapter({ data, year });

    await extra.api.post(
      `/users/${userId}/studentDetails/${studentDetailsId}/forms/phd_exams`,
      body
    );

    if (isWindow) {
      dispatch(notificationActions.setSuccess(""));
    } else {
      nextPage(thunkApi);
    }

    return payload;
  } catch ({ response: error }: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});
