import { PersonalMainLayout } from "@Widgets/PersonalMainLayout";
import { FC, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentDetailsActiveUserId,
  getUserAccessableSettings,
  getUserDegree,
  getUserId,
  getUserTheme,
} from "@Entities/User";
import useTranslation from "next-translate/useTranslation";
import { Col } from "react-bootstrap";
import clsx from "clsx";
import LinkCustom from "@/components/_Common/Link";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";

import Hipster from "@/public/images/hipster.svg";
import HipsterEn from "@/public/images/hipster_en.svg";
import { Question } from "../Question/Question";
import { CreateStatement } from "../CreateStatement/CreateStatement";
import styles from "./IndexPage.module.scss";
import { MyStatement } from "../MyStatement";
import { ThemeEnum } from "@Shared/types/themeTypes";
import { PersonalTabs } from "../../../../Features/PersonalTabs";
import { fetchApplication } from "@Entities/Application/model/services/fetchApplication";
import { getApplication } from "@Entities/Application/model/selectors/getApplication";

export const IndexPage: FC = () => {
  const { t, lang } = useTranslation("home");

  const degree = useSelector(getUserDegree);
  const theme = useSelector(getUserTheme);
  const accessibleSettings = useSelector(getUserAccessableSettings);
  const userId = useSelector(getUserId);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const application = useSelector(getApplication);

  const dispatch = useDispatch();

  useEffect(() => {
    if (studentDetailsId) {
      dispatch(fetchApplication({ userId, studentDetailsId }));
    }
  }, [degree]);

  const hipsterClasses: Record<string, boolean> = {
    [styles.contact__picture_accessible]: theme === ThemeEnum.ACCESSIBLE,
    ["d-none"]: theme === "accessible" && !accessibleSettings.images,
  };

  return (
    <PersonalMainLayout>
      <PersonalTabs />
      <Container className="px-3" id="abitlk_mainWrapper">
        {!!application?.id ? <MyStatement /> : <CreateStatement />}

        <h2 className={styles.heading}>{t("faq.heading")}</h2>

        <div className={styles.faq}>
          <Col className={styles.faq__left} lg={6}>
            <Question
              answerText={t("faq.one.answer")}
              questionText={t("faq.one.question")}
            />
            <Question
              answerText={t("faq.three.answer")}
              questionText={t("faq.three.question")}
            />
          </Col>
          <Col className={styles.faq__right} lg={6}>
            <Question
              answerText={t("faq.five.answer")}
              questionText={t("faq.five.question")}
            />
            <Question
              answerText={t("faq.four.answer")}
              questionText={t("faq.four.question")}
            />
          </Col>
        </div>

        <Row className={styles.contact}>
          <Col
            lg={{ span: 6 }}
            md={{ span: 12 }}
            sm={{ span: 7, order: 1 }}
            xl={{ span: 5 }}
            xs={{ order: 2 }}
          >
            <h2 className={styles.heading}>{t("contact.heading")}</h2>
            <p className={styles.contact__paragraph}>
              {`${t("contact.description.abit")} `}
              <LinkCustom
                className="link_inline"
                href={`mailto:${t("contact.email.abit")}`}
              >
                {t("contact.email.abit")}
              </LinkCustom>
            </p>
            <p className={styles.contact__paragraph}>
              {`${t("contact.description.postgraduate")} `}
              <LinkCustom
                className="link_inline"
                href={`mailto:${t("contact.email.postgraduate")}`}
              >
                {t("contact.email.postgraduate")}
              </LinkCustom>
            </p>
            <p className={styles.contact__paragraph}>
              {`${t("contact.description.foreign")} `}
              <LinkCustom
                className="link_inline"
                href={`mailto:${t("contact.email.foreign")}`}
              >
                {t("contact.email.foreign")}
              </LinkCustom>
            </p>
          </Col>
          <Col
            md={{ offset: 1 }}
            sm={{ span: 5, order: 2 }}
            xl={{ offset: 2 }}
            className={clsx(
              styles["contact__picture-wrapper"],
              "d-flex justify-content-center d-sm-block",
              "mb-4 mb-sm-0"
            )}
          >
            {lang === LanguagesLocalesEnum.RU ? (
              <Hipster
                className={clsx(styles.contact__picture, hipsterClasses)}
              />
            ) : (
              <HipsterEn
                className={clsx(styles.contact__picture, hipsterClasses)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </PersonalMainLayout>
  );
};
