import { FC } from "react";
import useTranslation from "next-translate/useTranslation";
import { Button } from "@Shared/ui/Button";
import styles from "./PageError.module.scss";
import { DefaultLayout } from "@Widgets/DefaultLayout";

interface PageErrorProps {
  authError?: boolean
}

export const PageError: FC<PageErrorProps> = ({ authError = false }) => {
  const { t, lang } = useTranslation("errorpage");

  const onBackHomeClick = () => {
    window.location.replace(`/${lang === "ru" ? "" : "en"}`);
  };

  return (
    <div className={styles.wrapper}>
      <DefaultLayout>
        <div className={styles.main}>
          <div className={styles.content}>
            <h1 className={styles.title}>{authError ?  t("errorBoundary.auth") : t("errorBoundary.title")}</h1>
            <div className={styles.controls}>
              <Button onClick={onBackHomeClick}>{t("errorBoundary.backToMainPage")}</Button>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </div>
  );
};
