import Cookies from "js-cookie";
import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios, { AxiosRequestConfig } from "axios";
import {
  AuthDefaultValueType,
  AuthProviderPropsType,
  AuthRequireOptionsType,
  ImpossibleErrorType,
  LanguagesEnum,
  LoginWithCodeResponse,
} from "../types/authTypes";
import {
  ACCESS_TOKEN_KEY,
  AuthErrorsMessages,
  AuthLocalStorageItemEnum,
  AuthLocalStorageKeyEnum,
  CODE_URL_PARAM_NAME,
  REFRESH_TOKEN_KEY,
} from "../lib/constants/constants";
import { AuthContext } from "../lib/authContext/authContext";
import { api } from "@Shared/api/createAxiosApi";
import {
  CheckAccessTokenMiddleware,
  CheckAccessTokenMiddlewareOptions,
} from "../lib/middlewares/CheckAccessTokenMiddleware";
import { setUserCookies } from "../lib/setUserCookies/setUserCookies";
import { useRouter } from "next/router";
import UserRoleEnum from "@/lib/enums/userRole";
import clearThemeVariables from "@/lib/themes/clearThemeVariables";
import { useDispatch } from "react-redux";
import { UserActions } from "@Entities/User";
import { Loader } from "@Entities/Loader";
import { getAvailableDegreesByWindow } from "@/lib/getAvailableDegrees";
import { PageError } from "@Pages/PageError";
import { captureException } from "@sentry/nextjs";

const defaultProviderValues: AuthDefaultValueType = {
  isAuthenticated: false,
  isLoading: true,
  error: null,
  user: null,
  getAccessToken: null,
  logoutHandler: null,
};

type StatusHandlers = { [key: number]: () => Promise<void> };

let isAuthorizationProgress = false;

export const AuthProvider: FC<AuthProviderPropsType> = (props) => {
  const { children, lang = LanguagesEnum.RU } = props;

  const dispatch = useDispatch();
  const [providerValues, setProviderValues] = useState<AuthDefaultValueType>(
    defaultProviderValues
  );
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [errorAuth, setErrorAuth] = useState(false);
  const [error, setError] = useState<ImpossibleErrorType>(null);
  const AuthRequireOptions = useRef(
    null
  ) as MutableRefObject<AuthRequireOptionsType | null>;
  const router = useRouter();

  const handleLogin = async (redirectUrl: any, locale: any) => {
    const response = await axios.get("/api/v1/sso/uri/login", {
      params: {
        redirect_uri: redirectUrl,
        locale,
      },
    });
    if (response.data) {
      if (Number(localStorage.getItem(AuthLocalStorageItemEnum.ERROR_COUNT)) > 3) {
        localStorage.removeItem(AuthLocalStorageItemEnum.ERROR_COUNT);
      } else {
        const authRedirectUrl = response.data.result.uri;
        router.replace(authRedirectUrl);
      }
    }
  };

  const handleAuth = async (code: any, redirectUrl: any, locale: any) => {
    const response = await axios.get<LoginWithCodeResponse>(
      "/api/v1/sso/auth",
      {
        params: {
          code,
          redirect_uri: redirectUrl,
          locale,
        },
      }
    ).finally(() => {
      localStorage.setItem(AuthLocalStorageItemEnum.AUTHORIZATION, AuthLocalStorageKeyEnum.SUCCESS);
    });
    setUserCookies(response);
  };

  const authUserByCurrentStatus = async (options: AuthRequireOptionsType) => {
    const {
      accessToken,
      refreshToken,
      code,
      redirectUrl,
      lang: locale,
    } = options;

    if (isAuthorizationProgress) {
      return;
    }

    const { pathname } = router;

    const createStatusHandlers = (
      checkAccessTokenMiddleware: (options: CheckAccessTokenMiddlewareOptions) => Promise<AxiosRequestConfig>,
      optionsMiddleware: CheckAccessTokenMiddlewareOptions
    ): StatusHandlers => {
      return {
        401: async () => {
          Cookies.remove(ACCESS_TOKEN_KEY);
          await checkAccessTokenMiddleware(optionsMiddleware);
        },
        400: async () => {
          if (!accessToken && refreshToken) {
            await checkAccessTokenMiddleware(optionsMiddleware);
          }
        },
        500: async () => {
          Cookies.remove(REFRESH_TOKEN_KEY);
          Cookies.remove(ACCESS_TOKEN_KEY);
        },
      };
    };

    isAuthorizationProgress = true;
    if (accessToken && refreshToken && !code) {
      await handleAuthorization(options);
    }
    const { localStorage } = window;
    const firstTimeAuthorization = localStorage.getItem(AuthLocalStorageItemEnum.AUTHORIZATION);

    if (code) {
      try {
        if (firstTimeAuthorization !== AuthLocalStorageKeyEnum.SUCCESS) {
          await handleAuth(code, redirectUrl, locale);
          const accessTokenUpdated = Cookies.get(ACCESS_TOKEN_KEY);
          const refreshTokenUpdated = Cookies.get(REFRESH_TOKEN_KEY);
          if (accessTokenUpdated && refreshTokenUpdated && code) {
            localStorage.removeItem(AuthLocalStorageItemEnum.AUTHORIZATION);
            router.replace({ pathname });
            await handleAuthorization({
              ...options,
              accessToken: accessTokenUpdated,
              refreshToken: refreshTokenUpdated,
            });
          }
        }
      } catch (err: any) {
        const config = err.response.config;
        const status = err.response.status;
        const optionsMiddleware: CheckAccessTokenMiddlewareOptions = {
          AuthRequireOptions,
          authUserByCurrentStatus,
          config,
          lang,
        };
        const statusHandlers = createStatusHandlers(CheckAccessTokenMiddleware, optionsMiddleware);
        const handler = statusHandlers[status];
        if (handler) {
          await handler();
        }

        localStorage.setItem(AuthLocalStorageItemEnum.AUTHORIZATION, AuthLocalStorageKeyEnum.ERROR);
        const errorCount = (Number(localStorage.getItem(AuthLocalStorageItemEnum.ERROR_COUNT)) || 0) + 1;
        localStorage.setItem(AuthLocalStorageItemEnum.ERROR_COUNT, String(errorCount));
        await handleLogin(redirectUrl, locale);
        if (errorCount > 3) {
          setErrorAuth(true);
        }
        captureException(err);
      }
    }

    if (!refreshToken || !accessToken) {
      try {
        if (!code) {
          await handleLogin(redirectUrl, locale);
        }
      } catch (err: any) {
        setError(AuthErrorsMessages.AuthWithRedirectErrorMessage);
      }
    }

    if (accessToken && refreshToken && code) {
      localStorage.removeItem(AuthLocalStorageItemEnum.AUTHORIZATION);
      router.replace({ pathname });
      await handleAuthorization(options);
    }
    isAuthorizationProgress = false;
  };

  api.interceptors.request.use(async (config) => {
    const options: CheckAccessTokenMiddlewareOptions = {
      AuthRequireOptions,
      authUserByCurrentStatus,
      config,
      lang,
    };
    return await CheckAccessTokenMiddleware(options);
  });

  api.interceptors.response.use(
    async (response) => response,
    async (error) => {
      const config: AxiosRequestConfig = error?.config;
      const options: CheckAccessTokenMiddlewareOptions = {
        AuthRequireOptions,
        authUserByCurrentStatus,
        config,
        lang,
      };
      if (
        error?.response?.status === 401 && config?.url &&
        !config.url.includes("/sso/refresh")
      ) {
        Cookies.remove(ACCESS_TOKEN_KEY);
        CheckAccessTokenMiddleware(options);
        return api.request(config);
      }
      return Promise.reject(error);
    }
  );

  const getAccessToken = useCallback(() => {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }, []);

  const logoutHandler = useCallback(async () => {
    const response = await api.get("/sso/uri/logout", {
      params: {
        redirect_uri:
          (lang === LanguagesEnum.EN ? `/${lang}` : "") + router.asPath,
        locale: lang,
      },
    });

    if (response.data.ok) {
      const logoutUrl = response.data.result.uri;
      clearThemeVariables();
      Cookies.remove(REFRESH_TOKEN_KEY);
      Cookies.remove(ACCESS_TOKEN_KEY);
      router.replace(logoutUrl);
    }
  }, []);

  const handleUserApplicationAccess = async (user: IUserData) => {
    const isWindowUser = user.roles.includes(UserRoleEnum.ROLE_WINDOW);
    const isQuestionnairePage = router.pathname
      .split("/")
      .includes("questionnaire");
    const isWindowRoute = router.pathname.split("/").includes("window");
    const availableDegrees = getAvailableDegreesByWindow(user.windows).length
      ? getAvailableDegreesByWindow(user.windows)
      : user.manager_degrees;

    if (isWindowUser && !isQuestionnairePage && isWindowRoute) {
      return router.push(router.asPath);
    }

    if (isWindowUser && !isQuestionnairePage && !isWindowRoute) {
      return router.push(`/window/${availableDegrees.at(0)}`);
    }
  };

  const handleAuthorization = async ({
    accessToken,
    refreshToken,
  }: AuthRequireOptionsType) => {
    const userResponse = await api.get("/users/");
    const configurationResponse = await api.get("/configurations");

    if (configurationResponse.data.ok) {
      const configuration = configurationResponse.data.result;
      const payload = {
        year: configuration.reception_company_year,
        acceptance: configuration.reception_company_in_process,
        changeAchievement:
          configuration.allowed_postgraduate_change_achievement,
      };
      dispatch(UserActions.setConfiguration(payload));
    }

    if (userResponse.data.ok) {
      const user = userResponse.data.result.user;

      dispatch(UserActions.setUser(user));

      await handleUserApplicationAccess(user);

      setProviderValues({
        isLoading: !isMounted,
        isAuthenticated: Boolean(accessToken && refreshToken),
        error: error,
        user: user,
        getAccessToken: getAccessToken,
        logoutHandler: logoutHandler,
      });
      setIsMounted(true);
    }
  };

  const loginAuthorization = async () => {
    const urlParams = new URLSearchParams(window.location.href);
    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
    const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
    const code = urlParams.get(CODE_URL_PARAM_NAME);
    AuthRequireOptions.current = {
      lang,
      redirectUrl:
        (lang === LanguagesEnum.EN ? `/${lang}` : "") + router.pathname,
      accessToken: accessToken,
      refreshToken: refreshToken,
      code: code,
    };

    await authUserByCurrentStatus(AuthRequireOptions.current);
  };

  useEffect(() => {
    loginAuthorization();
  }, []);

  if (errorAuth) {
    return (
      <PageError authError />
    );
  }

  if (!isMounted) {
    return <Loader infinite />;
  }

  return (
    <AuthContext.Provider value={providerValues}>
      {children}
    </AuthContext.Provider>
  );
};
