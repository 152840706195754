import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "loader",
  initialState: { status: false },
  reducers: {
    startLoading: (state) => {
      state.status = true;
    },
    stopLoading: (state) => {
      state.status = false;
    },
  },
});

export const { reducer: loaderReducer } = loaderSlice;
export const { actions: loaderActions } = loaderSlice;
