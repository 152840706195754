import { FC, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "next-translate/useTranslation";
import { Modal } from "@Shared/ui/Modal";
import { Form } from "react-bootstrap";
import {
  UserActions,
  getStudentDetails,
  getUserId,
  setStudentdetails,
  updateStudentDetailsActive,
} from "@Entities/User";
import { FetchPersonalData } from "@Features/PersonalForm";
import { setChosenPrograms } from "@Entities/Form";
import styles from "./ConfirmChangeDegree.module.scss";
import { api } from "@Shared/api/createAxiosApi";
import ButtonCustom from "@/components/_Common/ButtonCustom";

interface ConfirmChangeDegreeProps {
  showModal: boolean;
  closeModal: () => void;
  newActiveDegree: IDegree | null;
}

export const ConfirmChangeDegree: FC<ConfirmChangeDegreeProps> = (props) => {
  const { showModal, closeModal, newActiveDegree } = props;

  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const studentDetailsList = useSelector(getStudentDetails);

  const onSubmit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const studentDetailsActive = studentDetailsList.find(
      (item: IStudentDetails) => item.degree === newActiveDegree
    );

    if (!studentDetailsActive) {
      await api.put(`/users/${userId}/studentDetails/`, {
        degree: newActiveDegree,
        first_time_degree: false,
      });
      const response = await api.get(`/users/${userId}/studentDetails/`);
      const studentDetailsList = response.data.result.student_details;

      const studentDetailsActive = studentDetailsList.find(
        (item: IStudentDetails) => item.degree === newActiveDegree
      );

      const payload = { ...studentDetailsActive, active: true };
      dispatch(UserActions.setStudentDetailsActive(payload));
      dispatch(
        updateStudentDetailsActive({
          userId,
          studentDetailsId: payload.id,
          degree: newActiveDegree,
        })
      );
      dispatch(FetchPersonalData({ userId, studentDetailsId: payload.id }));
      dispatch(setChosenPrograms({ userId, studentDetailsId: payload.id }));
      dispatch(setStudentdetails({ userId }));
      closeModal();
    }
    if (studentDetailsActive) {
      const payload = { ...studentDetailsActive, active: true };
      dispatch(UserActions.setStudentDetailsActive(payload));
      dispatch(
        updateStudentDetailsActive({
          userId,
          studentDetailsId: studentDetailsActive.id,
          degree: newActiveDegree,
        })
      );
      dispatch(
        FetchPersonalData({ userId, studentDetailsId: studentDetailsActive.id })
      );
      dispatch(
        setChosenPrograms({ userId, studentDetailsId: studentDetailsActive.id })
      );
      closeModal();
    }
  };

  return (
    <Modal closeModal={closeModal} id="modalRoles" showModal={showModal}>
      <Form className={styles.modal} onSubmit={onSubmit}>
        <h3>{t("changeDegree.title")}</h3>
        <p>{t("changeDegree.desc")}</p>
        <p>
          {t("changeDegree.newDegree")}{" "}
          {t(`changeDegree.degree.${newActiveDegree}`)}
        </p>

        <p>{t("changeDegree.confirmation")}</p>

        <div className={styles.modal__buttons} id="modalButtons">
          <ButtonCustom
            id="modalButtonNein"
            variant="outline-primary"
            onClick={closeModal}
          >
            {t("changeDegree.nein")}
          </ButtonCustom>
          <ButtonCustom id="modalButtonSi" type="submit">
            {t("changeDegree.si")}
          </ButtonCustom>
        </div>
      </Form>
    </Modal>
  );
};
