import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { createContext, FC, PropsWithChildren } from "react";

export interface IQueryParamsContext<T extends IQueryParams<string>> {
  queryParams: T;
  setQueryParams: (queryParams: IQueryParams<string>) => void;
  addQueryParams: (queryParams: IQueryParams<string>) => void;
  removeQueryParams: (options?: { remove?: string[]; keep?: string[] }) => void;
}

const initialContext: IQueryParamsContext<any> = {
  queryParams: {},
  addQueryParams: () => {
    return;
  },
  removeQueryParams: () => {
    return;
  },
  setQueryParams: () => {
    return;
  },
};

export const QueryParamsContext =
  createContext<IQueryParamsContext<any>>(initialContext);

export const ProvideQueryParams: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const setQueryParams = (queryParams: IQueryParams<string>) => {
    router.replace({
      query: { ...queryParams },
    });
  };

  const addQueryParams = (queryParams: IQueryParams<string>) => {
    const newParams: ParsedUrlQueryInput = {
      ...router.query,
      ...queryParams,
    };

    Object.keys(newParams).forEach(
      (key) => !newParams[key] && delete newParams[key]
    );

    router.replace({
      query: newParams,
    });
  };

  const removeQueryParams = (options?: {
    // Если не прокидывать options, удалятся все кроме degree
    remove?: string[]; // Удаляем только remove[] (нельзя использовать вместе с keep)
    keep?: string[]; // Удаляем все кроме keep[] (нельзя использовать вместе с remove)
  }) => {
    if (!options) {
      Object.keys(router.query)
        .filter((key) => key !== "degree")
        .forEach((param) => delete router.query[param]);

      router.replace({
        query: router.query,
      });
      return;
    }

    const { remove, keep } = options;

    if (remove?.length) {
      remove.forEach((param) => delete router.query[param]);
    }

    if (keep?.length) {
      Object.keys(router.query)
        .filter((key) => key !== "degree")
        .filter((key) => !keep?.includes(key))
        .forEach((param) => delete router.query[param]);
    }

    router.replace({
      query: router.query,
    });
  };

  return (
    <QueryParamsContext.Provider
      value={{
        queryParams: router.query,
        setQueryParams,
        addQueryParams,
        removeQueryParams,
      }}
    >
      {children}
    </QueryParamsContext.Provider>
  );
};
