import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Application } from "../types/applicationType";
import getErrorMessage from "@/lib/_common/getErrorMessage";

interface FetchApplicationOptions {
  userId: number;
  studentDetailsId: number;
}

export const fetchApplication = createAsyncThunk<
  Application,
  FetchApplicationOptions,
  ThunkApiType<string>
>("fetchApplication", async (options, thunkApi) => {
  const { userId, studentDetailsId } = options;
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get(
      `/users/${userId}/studentDetails/${studentDetailsId}/application`
    );


    return response.data.result;
  } catch ({ response: error }: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});
