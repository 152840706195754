import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "@Shared/api/createAxiosApi";
import { RequestOptions } from "../../types/modelTypes";
import { EntranceMasterExamsSchema } from "../../types/formTypes";
import DegreeIdEnum from "@/lib/enums/degreeIdEnum";
import getErrorMessage from "@/lib/_common/getErrorMessage";
import { notificationActions } from "@Entities/Notification/model/slice/notificationSlice";

export const postMasterExams = createAsyncThunk<
  EntranceMasterExamsSchema[],
  RequestOptions<EntranceMasterExamsSchema>,
  { rejectValue: string }
>(
  "masterExams/postMasterExams",
  async (options, { rejectWithValue, dispatch }) => {
    const { userId, studentDetailsId, body, isWindow } = options;

    const requestExamsBody = body?.exams?.map((item) => ({
      id: item.examId,
      discipline_id: item.discipline_id,
      selected_program_id: item.selected_program_id,
      type_id: DegreeIdEnum.MASTER,
      exam_date_id: item.exam_date?.id ?? "",
      file: item.file?.at(0),
      track_id: item.track_id,
    }));

    const requestBody = {
      is_have_exams: true,
      exams: requestExamsBody,
    };

    const url = `/users/${userId}/studentDetails/${studentDetailsId}/forms/master_exams`;

    try {
      const response = await api.post(url, requestBody);

      if (isWindow) {
        dispatch(notificationActions.setSuccess(""));
      }

      return response.data;
    } catch ({ response: error }: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
