import getErrorMessage from "@/lib/_common/getErrorMessage";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface StudentdetailsProps {
    userId: number
}

export const setStudentdetails = createAsyncThunk<IStudentDetails[], StudentdetailsProps, ThunkApiType<string>>(
  "user/studentdetails",
  async (data, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    const { userId } = data;

    try {
      const response = await extra.api.get(`/users/${userId}/studentDetails/`);
      const payload = response.data.result.student_details;

      return payload;
    } catch ({ response: error }: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
