import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { api } from "@Shared/api/createAxiosApi";
import getErrorMessage from "@/lib/_common/getErrorMessage";
import camelcaseKeys from "camelcase-keys";
import DegreeEnum from "@/lib/enums/degree";

export interface fetchCompetitiveGroupsParams {
  year: number;
  degree: DegreeEnum;
}

export const fetchCompetitiveGroups = createAsyncThunk<
  IWindowCompetitiveGroup[],
  fetchCompetitiveGroupsParams,
  ThunkApiType<string>
>("window/setFormsCompetitiveGroup", async (params, thunkApi) => {
  const { rejectWithValue } = thunkApi;

  try {
    const response = await api.get("/glossary/competitive_groups", { params });
    return camelcaseKeys(response.data.result.items);
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});
