import RoutesNamesEnum from "../enums/routesNames";

const globalRoutes: {
  personal: {
    itmostars: RoutesNamesEnum.PERSONAL_ITMOSTARS;
    medical: RoutesNamesEnum.PERSONAL_MEDICAL;
    specialty: RoutesNamesEnum.PERSONAL_SPECIALTY;
    address: RoutesNamesEnum.PERSONAL_ADDRESS;
    education: RoutesNamesEnum.PERSONAL_EDUCATION;
    achievements: RoutesNamesEnum.PERSONAL_ACHIEVEMENTS;
    data: RoutesNamesEnum.PERSONAL_DATA;
    documents: RoutesNamesEnum.PERSONAL_DOCUMENTS;
    dormitory: RoutesNamesEnum.PERSONAL_DORMITORY;
    photo: RoutesNamesEnum.PERSONAL_PHOTO;
    relatives: RoutesNamesEnum.PERSONAL_RELATIVES;
    kronbars: RoutesNamesEnum.PERSONAL_KRONBARS;
    exams: RoutesNamesEnum.PERSONAL_EXAMS;
    priveleges: RoutesNamesEnum.PERSONAL_PRIVELEGES;
    military: RoutesNamesEnum.PERSONAL_MILITARY;
    payment: RoutesNamesEnum.PERSONAL_PAYMENT;
    programs: RoutesNamesEnum.PERSONAL_PROGRAMS;
    olympiads: RoutesNamesEnum.PERSONAL_OLYMPIADS;
  };
  programs: RoutesNamesEnum.PROGRAMS;
  window: (degree: IDegree) => {
    tables: {
      all: { name: RoutesNamesEnum.WINDOW_TABLE_ALL; to: string };
      new: { name: RoutesNamesEnum.WINDOW_TABLE_NEW; to: string };
      cases: { name: RoutesNamesEnum.WINDOW_TABLE_CASES; to: string };
      rejected: { name: RoutesNamesEnum.WINDOW_TABLE_REJECTED; to: string };
      documents: { name: RoutesNamesEnum.WINDOW_TABLE_DOCUMENTS; to: string };
      sspvo: { name: RoutesNamesEnum.WINDOW_TABLE_SSPVO; to: string };
    };
    contract: {
      applications: {
        name: RoutesNamesEnum.WINDOW_CONTRACT_APPLICATIONS;
        to: string;
      };
    };
    forms: { name: RoutesNamesEnum.WINDOW_FORMS; to: string };
    questionnaire: (
      userId: any,
      studentDetailsId: any
    ) => {
      itmostars: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_ITMOSTARS;
        to: string;
      };
      specialty: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_DOCUMENTS;
        to: string;
      };
      medical: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_MEDICAL;
        to: string;
      };
      address: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_ADDRESS;
        to: string;
      };
      education: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_EDUCATION;
        to: string;
      };
      achievements: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_ACHIEVEMENTS;
        to: string;
      };
      data: { name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_DATA; to: string };
      documents: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_DOCUMENTS;
        to: string;
      };
      photo: { name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PHOTO; to: string };
      relatives: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_RELATIVES;
        to: string;
      };
      kronbars: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_KRONBARS;
        to: string;
      };
      exams: { name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_EXAMS; to: string };
      programsSelect: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PROGRAMS_SELECT;
        to: string;
      };
      priveleges: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PRIVELEGES;
        to: string;
      };
      quota: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_EDUCATION;
        to: string;
      };
      military: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_MILITARY;
        to: string;
      };
      programs: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PROGRAMS;
        to: string;
      };
      olympiads: {
        name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_OLYMPIADS;
        to: string;
      };
    };
    ratings: {
      ranking: { name: RoutesNamesEnum.WINDOW_RATINGS_RANKING; to: string };
      list: { name: RoutesNamesEnum.WINDOW_RATINGS_LIST; to: string };
    };
    examination: {
      specialConditions: {
        name: RoutesNamesEnum.WINDOW_TABLE_SPECIAL_CONDITIONS;
        to: string;
      };
      articles: { name: RoutesNamesEnum.WINDOW_TABLE_ARTICLES; to: string };
      quota: { name: RoutesNamesEnum.WINDOW_TABLE_TARGET_QUOTA; to: string };
      piga: { name: RoutesNamesEnum.WINDOW_TABLE_PIGA; to: string };
      exams: { name: RoutesNamesEnum.WINDOW_TABLE_EXAMS; to: string };
      contests: { name: RoutesNamesEnum.WINDOW_TABLE_CONTESTS; to: string };
      ia: { name: RoutesNamesEnum.WINDOW_TABLE_IA; to: string };
      kronbarsStars: {
        name: RoutesNamesEnum.WINDOW_TABLE_KRONBARS_STARS;
        to: string;
      };
      itmoStars: { name: RoutesNamesEnum.WINDOW_TABLE_ITMO_STARS; to: string };
      email: { name: RoutesNamesEnum.WINDOW_TABLE_EMAIL; to: string };
      specialQuota: {
        name: RoutesNamesEnum.WINDOW_TABLE_SPECIAL_QUOTA;
        to: string;
      };
      unusualQuota: {
        name: RoutesNamesEnum.WINDOW_TABLE_UNUSUAL_QUOTA;
        to: string;
      };
      сompetitionPortfolio: {
        name: RoutesNamesEnum.WINDOW_TABLE_COMPETITION_PORTFOLIO;
        to: string;
      };
    };
    orders: {
      reportWithoutGroups: {
        name: RoutesNamesEnum.WINDOW_ORDERS_GROUPS_REPORT_WITHOUT_GROUPS;
        to: string;
      };
      formation: { name: RoutesNamesEnum.WINDOW_ORDERS_FORMATION; to: string };
      groupsEntry: {
        name: RoutesNamesEnum.WINDOW_ORDERS_GROUPS_ENTRY;
        to: string;
      };
    };
    dashboard: { name: RoutesNamesEnum.WINDOW_DASHBOARD; to: string };
    statistics: {
      by_faculties: {
        name: RoutesNamesEnum.WINDOW_STATISTICS_BY_FACULTIES;
        to: string;
      };
      by_programs: {
        name: RoutesNamesEnum.WINDOW_STATISTICS_BY_PROGRAMS;
        to: string;
      };
      competitive_groups: {
        name: RoutesNamesEnum.WINDOW_STATISTICS_COMPETITIVE_GROUPS;
        to: string;
      };
      filed_cases: {
        name: RoutesNamesEnum.WINDOW_STATISTICS_FILED_CASES;
        to: string;
      };
    };
  };
  home: RoutesNamesEnum.HOME;
} = {
  home: RoutesNamesEnum.HOME,

  programs: RoutesNamesEnum.PROGRAMS,

  personal: {
    programs: RoutesNamesEnum.PERSONAL_PROGRAMS,
    data: RoutesNamesEnum.PERSONAL_DATA,
    address: RoutesNamesEnum.PERSONAL_ADDRESS,
    photo: RoutesNamesEnum.PERSONAL_PHOTO,
    education: RoutesNamesEnum.PERSONAL_EDUCATION,
    exams: RoutesNamesEnum.PERSONAL_EXAMS,
    relatives: RoutesNamesEnum.PERSONAL_RELATIVES,
    medical: RoutesNamesEnum.PERSONAL_MEDICAL,
    military: RoutesNamesEnum.PERSONAL_MILITARY,
    priveleges: RoutesNamesEnum.PERSONAL_PRIVELEGES,
    olympiads: RoutesNamesEnum.PERSONAL_OLYMPIADS,
    achievements: RoutesNamesEnum.PERSONAL_ACHIEVEMENTS,
    itmostars: RoutesNamesEnum.PERSONAL_ITMOSTARS,
    kronbars: RoutesNamesEnum.PERSONAL_KRONBARS,
    documents: RoutesNamesEnum.PERSONAL_DOCUMENTS,
    payment: RoutesNamesEnum.PERSONAL_PAYMENT,
    specialty: RoutesNamesEnum.PERSONAL_SPECIALTY,
    dormitory: RoutesNamesEnum.PERSONAL_DORMITORY,
  },

  window: (degree) => {
    const windowPrefix = `/window/${degree}`;

    return {
      dashboard: {
        name: RoutesNamesEnum.WINDOW_DASHBOARD,
        to: `${windowPrefix}`,
      },

      tables: {
        new: {
          name: RoutesNamesEnum.WINDOW_TABLE_NEW,
          to: `${windowPrefix}/tables/new`,
        },
        cases: {
          name: RoutesNamesEnum.WINDOW_TABLE_CASES,
          to: `${windowPrefix}/tables/cases`,
        },
        rejected: {
          name: RoutesNamesEnum.WINDOW_TABLE_REJECTED,
          to: `${windowPrefix}/tables/rejected`,
        },
        all: {
          name: RoutesNamesEnum.WINDOW_TABLE_ALL,
          to: `${windowPrefix}/tables/all`,
        },
        documents: {
          name: RoutesNamesEnum.WINDOW_TABLE_DOCUMENTS,
          to: `${windowPrefix}/tables/documents`,
        },
        sspvo: {
          name: RoutesNamesEnum.WINDOW_TABLE_SSPVO,
          to: `${windowPrefix}/tables/sspvo`,
        },
      },

      contract: {
        applications: {
          name: RoutesNamesEnum.WINDOW_CONTRACT_APPLICATIONS,
          to: `${windowPrefix}/tables/all?financing=contract&case_formed=1`,
        },
      },

      forms: {
        name: RoutesNamesEnum.WINDOW_FORMS,
        to: `${windowPrefix}/forms/scores`,
      },

      examination: {
        ia: {
          name: RoutesNamesEnum.WINDOW_TABLE_IA,
          to: `${windowPrefix}/examination/ia`,
        },
        kronbarsStars: {
          name: RoutesNamesEnum.WINDOW_TABLE_KRONBARS_STARS,
          to: `${windowPrefix}/examination/kronbars-stars`,
        },
        itmoStars: {
          name: RoutesNamesEnum.WINDOW_TABLE_ITMO_STARS,
          to: `${windowPrefix}/examination/itmo-stars`,
        },
        articles: {
          name: RoutesNamesEnum.WINDOW_TABLE_ARTICLES,
          to: `${windowPrefix}/examination/articles`,
        },
        email: {
          name: RoutesNamesEnum.WINDOW_TABLE_EMAIL,
          to: `${windowPrefix}/examination/email`,
        },
        сompetitionPortfolio: {
          name: RoutesNamesEnum.WINDOW_TABLE_COMPETITION_PORTFOLIO,
          to: `${windowPrefix}/examination/competitionPortfolio`,
        },
        quota: {
          name: RoutesNamesEnum.WINDOW_TABLE_TARGET_QUOTA,
          to: `${windowPrefix}/examination/target-quota`,
        },
        piga: {
          name: RoutesNamesEnum.WINDOW_TABLE_PIGA,
          to: `${windowPrefix}/examination/piga`,
        },
        exams: {
          name: RoutesNamesEnum.WINDOW_TABLE_EXAMS,
          to: `${windowPrefix}/examination/exams`,
        },
        contests: {
          name: RoutesNamesEnum.WINDOW_TABLE_CONTESTS,
          to: `${windowPrefix}/examination/contests`,
        },
        specialConditions: {
          name: RoutesNamesEnum.WINDOW_TABLE_SPECIAL_CONDITIONS,
          to: `${windowPrefix}/examination/special-conditions`,
        },
        specialQuota: {
          name: RoutesNamesEnum.WINDOW_TABLE_SPECIAL_QUOTA,
          to: `${windowPrefix}/examination/special-quota`,
        },
        unusualQuota: {
          name: RoutesNamesEnum.WINDOW_TABLE_UNUSUAL_QUOTA,
          to: `${windowPrefix}/examination/unusual-quota`,
        },
      },

      orders: {
        formation: {
          name: RoutesNamesEnum.WINDOW_ORDERS_FORMATION,
          to: `${windowPrefix}/orders/formation`,
        },
        groupsEntry: {
          name: RoutesNamesEnum.WINDOW_ORDERS_GROUPS_ENTRY,
          to: `${windowPrefix}/orders/groupsEntry`,
        },
        reportWithoutGroups: {
          name: RoutesNamesEnum.WINDOW_ORDERS_GROUPS_REPORT_WITHOUT_GROUPS,
          to: `${windowPrefix}/orders/reportWithoutGroups`,
        },
      },

      ratings: {
        list: {
          name: RoutesNamesEnum.WINDOW_RATINGS_LIST,
          to: `${windowPrefix}/ratings/list`,
        },
        ranking: {
          name: RoutesNamesEnum.WINDOW_RATINGS_RANKING,
          to: `${windowPrefix}/ratings/ranking`,
        },
      },

      statistics: {
        by_programs: {
          name: RoutesNamesEnum.WINDOW_STATISTICS_BY_PROGRAMS,
          to: `${windowPrefix}/statistics/by_programs`,
        },
        competitive_groups: {
          name: RoutesNamesEnum.WINDOW_STATISTICS_COMPETITIVE_GROUPS,
          to: `${windowPrefix}/statistics/competitive_groups`,
        },
        by_faculties: {
          name: RoutesNamesEnum.WINDOW_STATISTICS_BY_FACULTIES,
          to: `${windowPrefix}/statistics/by_faculties`,
        },
        filed_cases: {
          name: RoutesNamesEnum.WINDOW_STATISTICS_FILED_CASES,
          to: `${windowPrefix}/statistics/filed_cases`,
        },
      },

      questionnaire: (userId: any, studentDetailsId) => {
        const questionnairePrefix = `${windowPrefix}/questionnaire/${userId}/${studentDetailsId}`;
        return {
          programs: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PROGRAMS,
            to: `${questionnairePrefix}/programs`,
          },
          programsSelect: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PROGRAMS_SELECT,
            to: `${questionnairePrefix}/programs-select`,
          },
          data: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_DATA,
            to: `${questionnairePrefix}/personally`,
          },
          address: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_ADDRESS,
            to: `${questionnairePrefix}/address`,
          },
          photo: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PHOTO,
            to: `${questionnairePrefix}/photo`,
          },
          education: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_EDUCATION,
            to: `${questionnairePrefix}/education`,
          },
          quota: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_EDUCATION,
            to: `${questionnairePrefix}/quota`,
          },
          specialty: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_DOCUMENTS,
            to: `${questionnairePrefix}/specialty`,
          },
          exams: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_EXAMS,
            to: `${questionnairePrefix}/exams`,
          },
          relatives: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_RELATIVES,
            to: `${questionnairePrefix}/relatives`,
          },
          medical: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_MEDICAL,
            to: `${questionnairePrefix}/medical`,
          },
          military: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_MILITARY,
            to: `${questionnairePrefix}/military`,
          },
          priveleges: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_PRIVELEGES,
            to: `${questionnairePrefix}/priveleges`,
          },
          olympiads: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_OLYMPIADS,
            to: `${questionnairePrefix}/olympiads`,
          },
          achievements: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_ACHIEVEMENTS,
            to: `${questionnairePrefix}/achievements`,
          },
          itmostars: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_ITMOSTARS,
            to: `${questionnairePrefix}/itmostars`,
          },
          kronbars: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_KRONBARS,
            to: `${questionnairePrefix}/kronbars`,
          },
          documents: {
            name: RoutesNamesEnum.QUESTIONNAIRE_PERSONAL_DOCUMENTS,
            to: `${questionnairePrefix}/documents`,
          },
        };
      },
    };
  },
};

export default globalRoutes;
