import React, { ChangeEvent, useState } from "react";
import { ClipboardEvent, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import {
  Controller,
  Resolver,
  FieldError,
  FormProvider,
  useForm,
  useWatch,
} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import useTranslation from "next-translate/useTranslation";
import ru from "react-phone-input-2/lang/ru.json";
import inputNumberHandle from "@/lib/_common/inputs/inputNumberHandle";
import "react-phone-input-2/lib/style.css";

import { Checkbox } from "@Shared/ui/Checkbox";
import { InputFile } from "@Shared/ui/InputFile";
import FormBorder from "@/components/_Common/Form/Border";
import FormLabel from "@/components/_Common/Form/Label";
import inputTrim from "@/lib/_common/inputs/inputTrim";
import inputDateHandle from "@/lib/_common/inputs/inputDateHandle";
import pasteDateHandle from "@/lib/_common/inputs/pasteDateHandle";
import { snilsMask } from "@/lib/_common/inputs/snilsMask";
import { useYup } from "@Shared/lib/yup/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { localCitizenship } from "@/lib/_common/formsConditions";
import { AsyncGarSelect } from "@Entities/AsyncGarSelect";
import { GarRequestsEnum } from "@Entities/AsyncGarSelect";
import { Select } from "@Shared/ui/Select";
import { Tooltip } from "@Shared/ui/Tooltip";

import { PersonalDataType } from "../../types/personalStateSchema";
import {
  PostPersonalData,
  PostPersonalDataOptions,
} from "../../model/services/PostPersonalData/PostPersonalData";
import { createPersonalSchema } from "../../lib/validationSchema";
import { ChangeCitizenOptions } from "../../types/personalTypes";
import { getPersonalData } from "../../model/selectors/getPersonalData/getPersonalData";
import {
  getIsSavedSuccessfully,
  getPersonalError,
} from "../../model/selectors/getComponentStates/getComponentStates";
import { PersonalActions } from "../../model/slice/PersonalSlice";
import { MimeType } from "@Shared/ui/InputFile/types";
import DocumentTypesEnum from "@Features/PersonalForm/lib/documentTypesEnum";
import { PassportForm } from "../DocumentForms/PassportForm";
import { InternationalPassportForm } from "../DocumentForms/InternationalPassportForm";
import { IDCardForm } from "../DocumentForms/IDCardForm";
import { ForeignPassportForm } from "../DocumentForms/ForeignPassportForm";
import { BirthCertificateForm } from "../DocumentForms/BirthCertificateForm";
import { ShelterCertificateForm } from "../DocumentForms/ShelterCertificateForm";
import { MilitaryDocumentForm } from "../DocumentForms/MilitaryDocumentForm";
import { useFetchDocumentTypesQuery } from "@/store/rtk/dictionariesApi";
import { IDictionaryItem } from "@/lib/services/dictionary.service";

interface PersonalFormAllProps {
  id: string;
  userId: number;
  studentDetailsId: number;
  disabled?: boolean;
  isWindow?: boolean;
}

export type ValidFieldNames =
    | "snils"
    | "citizenship"
    | "citizenship.label"
    | "citizenship.value"
    | "fullName"
    | "isHaveOldName"
    | "fullNameOld"
    | "fullNameOldDate"
    | "compatriotFile"
    | "compatriotFileTranslation";

const documentTypeForm = (
  documentType: DocumentTypesEnum,
  isForeigner: boolean,
  disabled: boolean
) => {
  switch (documentType) {
    case DocumentTypesEnum.InternationalPassport:
      return <InternationalPassportForm disabled={disabled} />;
    case DocumentTypesEnum.BirthCertificate:
      return <BirthCertificateForm disabled={disabled} />;
    case DocumentTypesEnum.ShelterCertificate:
      return <ShelterCertificateForm disabled={disabled} />;
    case DocumentTypesEnum.ConscriptionCertificate:
    case DocumentTypesEnum.MilitaryCertificate:
    case DocumentTypesEnum.MilitaryId:
    case DocumentTypesEnum.MilitaryIdReserve:
    case DocumentTypesEnum.TemporaryMilitaryId:
      return <MilitaryDocumentForm disabled={disabled} />;
    case DocumentTypesEnum.IDCard:
      return <IDCardForm disabled={disabled} />;
    default:
      return !isForeigner ? (
        <PassportForm disabled={disabled} />
      ) : (
        <ForeignPassportForm disabled={disabled} />
      );
  }
};

export const PersonalFormAll: FC<PersonalFormAllProps> = (props) => {
  const {
    id,
    userId,
    studentDetailsId,
    disabled = false,
    isWindow = false,
  } = props;

  const { t } = useTranslation("personal/personally");

  const dispatch = useDispatch();
  const personalData = useSelector(getPersonalData);
  const errorMessage = useSelector(getPersonalError);
  const isSavedSuccessfully = useSelector(getIsSavedSuccessfully);

  const yup = useYup();
  const personalSchema = createPersonalSchema({ yup });
  const methods = useForm<PersonalDataType>({
    resolver: yupResolver(
      personalSchema
    ) as unknown as Resolver<PersonalDataType>,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState,
    setValue,
  } = methods;

  const {
    isHaveOldName,
    documents,
    citizenship,
    isCompatriot,
  } = useWatch<PersonalDataType>({ control });

  const documentType = documents?.personId?.type?.value;
  const [isForeigner, setIsForeigner] = useState(
    citizenship?.label !== localCitizenship
  );
  const [firstTime, setFirstTime] = useState(false);

  useEffect(() => {
    if (firstTime && !isHaveOldName) {
      setValue("fullNameOld.lastName", "");
      setValue("fullNameOld.firstName", "");
      setValue("fullNameOld.middleName", "");
      setValue("fullNameOldDate", "");
    }
  }, [isHaveOldName]);

  const handlePasteDate = (e: ClipboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLElement;
    const name = target.dataset.name as ValidFieldNames;

    if (name) {
      pasteDateHandle(e);
      trigger(name);
    }
  };

  const handleChangeCitizenship = (options: ChangeCitizenOptions) => {
    const { newValue, onChange } = options;

    if (newValue) {
      const isCitizenPerson = newValue.value === localCitizenship;
      onChange(newValue);
      setIsForeigner(!isCitizenPerson);
      setValue("documents.personId.type", null);
    }
  };

  const handleTrim = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.trim();
  };

  const onSubmit = (data: PersonalDataType) => {
    try {
      const options: PostPersonalDataOptions = {
        userId,
        studentDetailsId,
        data,
        isWindow,
      };

      dispatch(PostPersonalData(options));

    } catch (err) {}
  };

  useEffect(() => {
    if (personalData && !firstTime) {
      reset(personalData);
      setIsForeigner(personalData?.citizenship?.label !== localCitizenship);
      setFirstTime(true);
    }
  }, [personalData, firstTime]);

  const { data: documentTypes } = useFetchDocumentTypesQuery(isForeigner ?? false, {
    skip: isForeigner === null,
  });

  return (
    <div>
      {errorMessage && (
        <Tooltip
          text={errorMessage}
          title={t("notification.titleError")}
          variant="error"
          onShowTimeout={() => dispatch(PersonalActions.resetError())}
        />
      )}
      {isSavedSuccessfully && (
        <Tooltip
          text={t("notification.success")}
          title={t("notification.titleSuccess")}
          variant="success"
          onShowTimeout={() =>
            dispatch(PersonalActions.setIsSavedSuccessfull(false))
          }
        />
      )}
      <FormProvider {...methods}>
        <Form id={id} onSubmit={handleSubmit(onSubmit)}>
          <p className="mb-0"> {t("info.first")} </p>
          <p>
            {t("info.second")}
            <span className="required-mark">*</span>.
          </p>
          <FormBorder />
          <Form.Group id="citizenshipGroup">
            <FormLabel required>{t("form.citizenship.label")}</FormLabel>
            <Controller
              control={control}
              name="citizenship"
              render={({ field }) => (
                <AsyncGarSelect
                  disabled={disabled}
                  id="citizenship"
                  requestDataType={GarRequestsEnum.Countries}
                  value={field.value as MapItem<string | number>}
                  onChange={(newValue) =>
                    handleChangeCitizenship({
                      newValue,
                      onChange: field.onChange,
                    })
                  }
                />
              )}
            />
            {formState.errors?.citizenship && (
              <Form.Control.Feedback type="invalid">
                {(formState.errors.citizenship as FieldError)?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row>
            <Form.Group as={Col} id="lastNameGroup" md={4} sm={6}>
              <FormLabel required>
                {t("form.fullName.lastName.label")}
              </FormLabel>
              <Controller
                name="fullName.lastName"
                render={({ field: { value, onChange } }) => (
                  <Form.Control
                    disabled={disabled}
                    id="lastName"
                    placeholder={t("form.fullName.lastName.placeholder")}
                    type="text"
                    value={value}
                    onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTrim(e)}
                    onChange={(newValue) => onChange(inputTrim(newValue))}
                  />
                )}
              />
              {formState.errors.fullName?.lastName && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.fullName.lastName?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} id="firstNameGroup" md={4} sm={6}>
              <FormLabel required>
                {t("form.fullName.firstName.label")}
              </FormLabel>
              <Controller
                name="fullName.firstName"
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    disabled={disabled}
                    id="firstName"
                    placeholder={t("form.fullName.firstName.placeholder")}
                    type="text"
                    value={value}
                    onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTrim(e)}
                    onChange={(newValue) => onChange(inputTrim(newValue))}
                  />
                )}
              />
              {formState.errors.fullName?.firstName && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.fullName.firstName?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} id="middleNameGroup" md={4}>
              <FormLabel hint type="personally">
                {t("form.fullName.middleName.label")}
              </FormLabel>
              <Controller
                control={control}
                name="fullName.middleName"
                render={({ field: { onChange, value } }) => (
                  <Form.Control
                    disabled={disabled}
                    id="middleName"
                    placeholder={t("form.fullName.middleName.placeholder")}
                    type="text"
                    value={value}
                    onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTrim(e)}
                    onChange={(newValue) => onChange(inputTrim(newValue))}
                  />
                )}
              />
              {formState.errors.fullName?.middleName && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.fullName.middleName?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <Form.Group id="isHaveOldNameGroup">
            <Controller
              control={control}
              name="isHaveOldName"
              render={({ field }) => (
                <Checkbox
                  disabled={disabled}
                  id="isHaveOldName"
                  label={t("form.isHaveOldName")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Form.Group>

          {isHaveOldName && (
            <Row>
              <Form.Group as={Col} id="lastNameOldGroup" md={6} sm={6}>
                <FormLabel required>
                  {t("form.fullNameOld.lastName.label")}
                </FormLabel>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name="fullNameOld.lastName"
                  render={({ field: { value, onChange } }) => (
                    <Form.Control
                      disabled={disabled}
                      id="oldLastName"
                      placeholder={t("form.fullNameOld.lastName.placeholder")}
                      type="text"
                      value={value}
                      onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTrim(e)}
                      onChange={(newValue) => onChange(inputTrim(newValue))}
                    />
                  )}
                />
                {formState.errors.fullNameOld?.lastName && (
                  <Form.Control.Feedback type="invalid">
                    {formState.errors.fullNameOld.lastName?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group as={Col} id="firstNameOldGroup" md={6} sm={6}>
                <FormLabel required>
                  {t("form.fullNameOld.firstName.label")}
                </FormLabel>
                <Controller
                  control={control}
                  name="fullNameOld.firstName"
                  render={({ field: { value, onChange } }) => (
                    <Form.Control
                      disabled={disabled}
                      id="oldFirstName"
                      placeholder={t("form.fullNameOld.firstName.placeholder")}
                      type="text"
                      value={value}
                      onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTrim(e)}
                      onChange={(newValue) => onChange(inputTrim(newValue))}
                    />
                  )}
                />
                {formState.errors.fullNameOld?.firstName && (
                  <Form.Control.Feedback type="invalid">
                    {formState.errors.fullNameOld.firstName?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group as={Col} id="middleNameOldGroup" md={6} sm={6}>
                <FormLabel>{t("form.fullNameOld.middleName.label")}</FormLabel>
                <Controller
                  control={control}
                  name="fullNameOld.middleName"
                  render={({ field: { value, onChange } }) => (
                    <Form.Control
                      disabled={disabled}
                      id="oldMiddleName"
                      placeholder={t("form.fullNameOld.middleName.placeholder")}
                      type="text"
                      value={value}
                      onBlur={(e: ChangeEvent<HTMLInputElement>) => handleTrim(e)}
                      onChange={(newValue) => onChange(inputTrim(newValue))}
                    />
                  )}
                />
                {formState.errors.fullNameOld?.middleName && (
                  <Form.Control.Feedback type="invalid">
                    {formState.errors.fullNameOld.middleName?.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Col as={Col} md={6} sm={6}>
                <FormLabel required>
                  {t("form.fullNameOldDate.label")}
                </FormLabel>
                <Controller
                  control={control}
                  name="fullNameOldDate"
                  render={({ field }) => (
                    <Form.Control
                      data-name={field.name}
                      disabled={disabled}
                      id="fullNameOldDate"
                      maxLength={10}
                      placeholder={t("form.fullNameOldDate.placeholder")}
                      type="text"
                      value={field.value}
                      onChange={field.onChange}
                      onInput={inputDateHandle}
                      onPaste={handlePasteDate}
                    />
                  )}
                />
                {formState.errors.fullNameOldDate && (
                  <Form.Control.Feedback type="invalid">
                    {formState.errors.fullNameOldDate?.message}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
          )}

          <FormBorder />
          <Row>
            <Form.Group as={Col} id="emailGroup" sm={6}>
              <FormLabel required>{t("form.contact.email.label")}</FormLabel>
              <Controller
                control={control}
                name="contact.email"
                render={({ field }) => (
                  <Form.Control
                    disabled={disabled}
                    id="email"
                    placeholder={t("form.contact.email.placeholder")}
                    type="text"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {formState.errors.contact?.email && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.contact.email?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} id="phoneGroup" sm={6}>
              <FormLabel required>{t("form.contact.phone.label")}</FormLabel>
              <Controller
                control={control}
                name="contact.phone"
                render={({ field }) => (
                  <PhoneInput
                    disableSearchIcon
                    enableSearch
                    copyNumbersOnly={false}
                    disabled={disabled}
                    inputProps={{ id: "phone" }}
                    localization={ru}
                    placeholder={t("form.contact.phone.placeholder")}
                    searchNotFound={t("form.contact.phone.searchNotFound")}
                    value={field.value}
                    searchPlaceholder={t(
                      "form.contact.phone.placeholderSearch"
                    )}
                    onChange={field.onChange}
                  />
                )}
              />
              {formState.errors.contact?.phone && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.contact.phone?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} id="birthDateGroup" md={4} sm={6}>
              <FormLabel required>{t("form.birthDate.label")}</FormLabel>
              <Controller
                control={control}
                name="birthDate"
                render={({ field }) => (
                  <Form.Control
                    data-name={field.name}
                    disabled={disabled}
                    id="birthDate"
                    maxLength={10}
                    placeholder={t("form.birthDate.placeholder")}
                    type="text"
                    value={field.value}
                    onChange={field.onChange}
                    onInput={inputDateHandle}
                    onPaste={handlePasteDate}
                  />
                )}
              />
              {formState.errors.birthDate && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.birthDate.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} id="birthPlaceGroup" md={8} sm={6}>
              <FormLabel required>{t("form.birthPlace.label")}</FormLabel>
              <Controller
                control={control}
                name="birthPlace"
                render={({ field }) => (
                  <Form.Control
                    disabled={disabled}
                    id="birthPlace"
                    placeholder={t("form.birthPlace.placeholder")}
                    type="text"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {formState.errors.birthPlace && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.birthPlace.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              className="d-flex align-items-center"
              id="genderGroup"
              md={9}
              sm={8}
            >
              <FormLabel required className="mb-0 d-flex align-items-center">
                {t("form.gender.label")}
              </FormLabel>
              <div className="d-flex ml-3">
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => (
                    <>
                      <Form.Check
                        checked={field.value === "M"}
                        className="mr-3"
                        disabled={disabled}
                        id="M"
                        label={t("form.gender.male")}
                        type="radio"
                        onChange={() => field.onChange("M")}
                      />
                      <Form.Check
                        checked={field.value === "F"}
                        className="mr-3"
                        disabled={disabled}
                        id="F"
                        label={t("form.gender.female")}
                        type="radio"
                        onChange={() => field.onChange("F")}
                      />
                    </>
                  )}
                />
              </div>
              {formState.errors.gender && (
                <Form.Control.Feedback type="invalid">
                  {formState.errors.gender.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>
          <FormBorder />
          <Form.Group id="documentsTypeGroup">
            <FormLabel required>{t("form.documents.type.label")}</FormLabel>
            <Controller
              control={control}
              name="documents.personId.type"
              render={({ field }) => (
                <Select
                  disabled={disabled}
                  id="personIdType"
                  options={documentTypes as IDictionaryItem[]}
                  placeholder={t("form.documents.type.placeholder")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {formState.errors.documents?.personId?.type && (
              <Form.Control.Feedback type="invalid">
                {
                  (formState.errors.documents.personId.type as FieldError)
                    .message
                }
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {!!documentType &&
                documentTypeForm(
                    documentType as DocumentTypesEnum,
                    isForeigner,
                    disabled
                )
          }

          <FormBorder />
          {!isForeigner && (
            <Row>
              <Form.Group as={Col} id="snilsNumberGroup" lg={8}>
                <FormLabel required>
                  {t("form.documents.snils.number.label")}
                </FormLabel>
                <Controller
                  control={control}
                  name="snils.number"
                  render={({ field }) => (
                    <Form.Control
                      disabled={disabled}
                      id="snilsNumber"
                      maxLength={14}
                      placeholder={t("form.documents.snils.number.placeholder")}
                      type="text"
                      value={snilsMask(field.value)}
                      onInput={inputNumberHandle}
                      onChange={(e) =>
                        field.onChange(snilsMask(e.target.value.trim()))
                      }
                    />
                  )}
                />
                {formState.errors?.snils?.number && (
                  <Form.Control.Feedback type="invalid">
                    {formState.errors?.snils.number.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Col xs={12}>
                <FormLabel required>
                  {t("form.documents.snils.scans.label")}
                </FormLabel>
                <Controller
                  control={control}
                  name="snils.scans"
                  render={({ field }) => (
                    <InputFile
                      category="snils"
                      disabled={disabled}
                      formats={[MimeType.Pdf, MimeType.Jpeg, MimeType.Png]}
                      hint={t("form.documents.personId.scans.hint")}
                      id="snilsScans"
                      maxNumFiles={1}
                      maxSize={10}
                      value={field.value}
                      placeholder={t(
                        "form.documents.personId.scans.placeholder"
                      )}
                      onChange={field.onChange}
                    />
                  )}
                />
                {formState.errors?.snils?.scans && (
                  <Form.Control.Feedback type="invalid">
                    {(formState.errors.snils.scans as any)?.message}
                  </Form.Control.Feedback>
                )}
              </Col>
              <FormBorder />
            </Row>
          )}
          {isForeigner && (
            <Form.Group id="isCompatriotGroup">
              <Controller
                control={control}
                name="isCompatriot"
                render={({ field }) => (
                  <Checkbox
                    disabled={disabled}
                    id="isCompatriot"
                    label={t("compatriot.title")}
                    value={field.value as boolean}
                    onChange={field.onChange}
                  />
                )}
              />
              {isCompatriot && (
                <Form.Group id="compatriotFileGroup">
                  <Col className="mt-2" xs={12}>
                    <FormLabel required>{t("compatriot.label")}</FormLabel>
                    <Controller
                      control={control}
                      name="compatriotFile"
                      render={({ field }) => (
                        <InputFile
                          category="compatriot"
                          disabled={disabled}
                          formats={[MimeType.Pdf, MimeType.Jpeg, MimeType.Png]}
                          hint={t("form.documents.personId.scans.hint")}
                          id="compatriotFile"
                          maxSize={10}
                          value={field.value as string[]}
                          placeholder={t(
                            "form.documents.personId.scans.placeholder"
                          )}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Col>
                  <Col className="mt-2" xs={12}>
                    <FormLabel>{t("compatriot.translation")}</FormLabel>
                    <Controller
                      control={control}
                      name="compatriotFileTranslation"
                      render={({ field }) => (
                        <InputFile
                          category="compatriot"
                          disabled={disabled}
                          formats={[MimeType.Pdf, MimeType.Jpeg, MimeType.Png]}
                          hint={t("form.documents.personId.scans.hint")}
                          id="compatriotFileTranslation"
                          maxSize={10}
                          value={field.value}
                          placeholder={t(
                            "form.documents.personId.scans.placeholder"
                          )}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Col>
                </Form.Group>
              )}
            </Form.Group>
          )}
        </Form>
      </FormProvider>
    </div>
  );
};
