import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BachelorExamType } from "../../types/bachelorTypes";
import { postExamsDataAdapter } from "../../lib/postExamsDataAdapter";
import { NextRouter } from "next/router";
import { nextPage } from "@Entities/Sidebar";
import getErrorMessage from "@/lib/_common/getErrorMessage";
import { notificationActions } from "@Entities/Notification/model/slice/notificationSlice";

export interface PostBachelorExamsDataType {
  is_have_exams: boolean;
  exams: BachelorExamType[];
  entranceTests: BachelorExamType[];
}

export interface PostBachelorExams {
  data: PostBachelorExamsDataType;
  userId: number;
  studentDetailsId: number;
  router: NextRouter;
  isWindow: boolean;
  degree: IDegree;
}

export const postBachelorExams = createAsyncThunk<
  BachelorExamType[],
  PostBachelorExams,
  ThunkApiType<string>
>("bachelorExams", async (options, thunkApi) => {
  const { userId, studentDetailsId, data, isWindow } = options;
  const { extra, rejectWithValue, dispatch } = thunkApi;

  try {
    const body = postExamsDataAdapter(data);
    await extra.api.post(
      `/users/${userId}/studentDetails/${studentDetailsId}/forms/exams`,
      body
    );

    if (isWindow) {
      dispatch(notificationActions.setSuccess(""));
    } else {
      nextPage(thunkApi);
    }

    return [...data.exams, ...data.entranceTests];
  } catch ({ response: error }: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});
