import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { api } from "@Shared/api/createAxiosApi";
import { AchievementCategoryEnum } from "@/lib/enums/dictionaries/achievementCategoryEnum";
import { achievementToRequest } from "@/lib/services/lib/convertAchievement";
import { formLink } from "@/lib/config";
import getErrorMessage from "@/lib/_common/getErrorMessage";

interface UpdateEntrantAchievementOptions {
  userId: number;
  studentDetailsId: number;
  achievement: StudentAchievement;
  category: AchievementCategoryEnum;
}

export const updateEntrantAchievement = createAsyncThunk<
    { achievementId: number },
    UpdateEntrantAchievementOptions,
    ThunkApiType<string>
>(
  "achievementSlice/updateEntrantAchievement",
  async (options, thunkApi) => {
    const { userId, studentDetailsId, achievement, category } = options;
    const { rejectWithValue } = thunkApi;

    try {
      await api.put(
        `${formLink(userId, studentDetailsId)}/forms/achievement/${achievement.id}`,
        achievementToRequest(achievement, category)
      );

      return { achievementId: achievement.id };
    } catch ({ response: error }: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

