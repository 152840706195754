import DegreeEnum from "@/lib/enums/degree";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getUserDegree } from "@Entities/User";
import { BachelorCard } from "../BachelorCard";
import { MasterCard } from "../MasterCard";
import { PostgraduateCard } from "../Postgraduate";

export const MyStatement: FC = () => {
  const degree = useSelector(getUserDegree);

  return (
    <div className="mb-5">
      {degree === DegreeEnum.BACHELOR && <BachelorCard />}
      {degree === DegreeEnum.MASTER && <MasterCard />}
      {degree === DegreeEnum.POSTGRADUATE && <PostgraduateCard />}
    </div>
  );
};
