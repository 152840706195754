import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { api } from "@Shared/api/createAxiosApi";
import getErrorMessage from "@/lib/_common/getErrorMessage";
import camelcaseKeys from "camelcase-keys";
import {
  IWindowProgramAdmissionQuota,
} from "@/types/window/forms";

export interface fetchAdmissionQuotasParams {
  year: number;
  degree: IDegree;
}

export const fetchAdmissionQuotas = createAsyncThunk<
  IWindowProgramAdmissionQuota[],
  fetchAdmissionQuotasParams,
  ThunkApiType<string>
>("window/fetchAdmissionQuotas", async (params, thunkApi) => {
  const { rejectWithValue } = thunkApi;

  try {
    const response = await api.get(
      "/glossary/competitive_groups/admission_quotas",
      {
        params: { ...params, page: 1, limit: 100 },
      }
    );

    return response.data.result.items.map((item: any) => ({
      ...camelcaseKeys(item),
      admissionQuotas: { ...camelcaseKeys(item.admission_quotas) },
    }));
  } catch (error: any) {
    return rejectWithValue(getErrorMessage(error));
  }
});
