import { localCitizenship } from "@/lib/_common/formsConditions";
import { PersonalDataType, PersonId } from "../types/personalStateSchema";
import {
  PassportOldType,
  PersonIdType,
  PostPersonallyData,
} from "../types/postRequestSchema";
import DocumentTypesEnum, { DocumentTypeEnum } from "./documentTypesEnum";

export const postDataAdapter = (data: PersonalDataType): PostPersonallyData => {
  const {
    citizenship,
    documents,
    gender,
    birthDate,
    birthPlace,
    contact,
    isHaveOldName,
    fullNameOldDate,
    fullNameOld,
    fullName,
    snils,
    isCompatriot,
    compatriotFile,
    compatriotFileTranslation,
    divisionCode,
  } = data;

  const isForeigner = citizenship.label !== localCitizenship;

  const snilsNumber = snils?.number?.replace(/-|\s/g, "");

  const passportOld =
    documents.passportOld &&
    ({
      series:
        documents.passportOld?.series.length > 0
          ? documents.passportOld?.series
          : undefined,
      number:
        documents.passportOld?.number.length > 0
          ? documents.passportOld?.number
          : undefined,
    } as PassportOldType);

  const fullNameOldData = fullNameOld?.firstName && {
    last_name: fullNameOld?.lastName || "",
    first_name: fullNameOld?.firstName || "",
    middle_name: fullNameOld?.middleName || "",
  };

  const snilsDocument =
    !isForeigner && snils.number && snils.scans.length
      ? {
        number: snilsNumber,
        scans: snils.scans,
      }
      : null;

  const personIdData = (
    documentType: DocumentTypesEnum,
    personId: PersonId
  ): PersonIdType => {
    switch (documentType) {
      case DocumentTypesEnum.InternationalPassport:
        return {
          scans: personId.internationalPassport?.scans || [],
          validity_period: personId.internationalPassport?.validityPeriod,
          division_code: divisionCode || "",
          division_name: personId.internationalPassport?.divisionName || "",
          issued_date: personId.internationalPassport?.issuedDate || "",
          number: personId.internationalPassport?.number || "",
          series: personId.internationalPassport?.series || "",
          type: "",
        };
      case DocumentTypesEnum.BirthCertificate:
        return {
          scans: personId.birthCertificate?.scans || [],
          issued_date: personId.birthCertificate?.issuedDate || "",
          number: personId.birthCertificate?.number || "",
          series: personId.birthCertificate?.series || "",
          type: "",
        };
      case DocumentTypesEnum.ShelterCertificate:
        return {
          scans: personId.shelterCertificate?.scans || [],
          validity_period: personId.shelterCertificate?.validityPeriod,
          issued_date: personId.shelterCertificate?.issuedDate || "",
          number: personId.shelterCertificate?.number || "",
          series: personId.shelterCertificate?.series || "",
          type: "",
        };
      case DocumentTypesEnum.ConscriptionCertificate:
      case DocumentTypesEnum.MilitaryCertificate:
      case DocumentTypesEnum.MilitaryId:
      case DocumentTypesEnum.MilitaryIdReserve:
      case DocumentTypesEnum.TemporaryMilitaryId:
        return { scans: personId.military?.scans || [], type: "" };
      case DocumentTypesEnum.IDCard:
        return {
          scans: personId.IDCard?.scans || [],
          translate_scans: personId.IDCard?.translateScans || [],
          validity_period: personId.IDCard?.validityPeriod,
          division_name: personId.IDCard?.divisionName || "",
          issued_date: personId.IDCard?.issuedDate || "",
          number: personId.IDCard?.number || "",
          type: "",
        };
      case DocumentTypesEnum.ForeignPassport:
        return {
          scans: personId.foreignPassport?.scans || [],
          translate_scans: personId.foreignPassport?.translateScans || [],
          validity_period: personId.foreignPassport?.validityPeriod,
          division_name: personId.foreignPassport?.divisionName || "",
          issued_date: personId.foreignPassport?.issuedDate || "",
          number: personId.foreignPassport?.number || "",
          type: "",
        };
      default:
        return {
          scans: personId.passport?.scans || [],
          division_code: divisionCode || "",
          division_name: personId.passport?.divisionName || "",
          issued_date: personId.passport?.issuedDate || "",
          number: personId.passport?.number || "",
          series: personId.passport?.series || "",
          type: "",
        };
    }
  };

  const body: PostPersonallyData = {
    citizenship_id: citizenship.id ?? citizenship.value,
    documents: {
      type: isForeigner
        ? DocumentTypeEnum.foreignPassport
        : DocumentTypeEnum.passport,
      is_have_old_passport: documents.isHaveOldPassport,
      passport_old: documents?.isHaveOldPassport ? passportOld || null : null,
      person_id: {
        ...personIdData(
          documents.personId.type?.value as DocumentTypesEnum,
          documents.personId
        ),
        type: documents.personId?.type
          ? documents.personId.type.value.toString()
          : "",
      },
      snils: snilsDocument,
    },
    gender: gender,
    birth_place: birthPlace,
    birth_date: birthDate,
    contact: {
      email: contact.email,
      phone: contact.phone,
    },
    is_have_old_name: isHaveOldName,
    full_name_old_date: fullNameOldDate,
    full_name_old: fullNameOldData || null,
    full_name: {
      last_name: fullName.lastName.trim(),
      first_name: fullName.firstName.trim(),
      middle_name: fullName.middleName.trim(),
    },
  };

  if (citizenship.label !== localCitizenship) {
    body.is_compatriot = Boolean(isCompatriot);
    (body.compatriot_file = compatriotFile?.at(0) || ""),
    (body.compatriot_file_translation =
        compatriotFileTranslation?.at(0) || "");
  }

  return body;
};
