import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { MutableRefObject } from "react";
import {
  ACCESS_TOKEN_KEY,
  CODE_URL_PARAM_NAME,
  REFRESH_TOKEN_KEY,
} from "../constants/constants";
import {
  AuthRequireOptionsType,
  LanguagesEnum,
  RefreshResponse,
} from "../../types/authTypes";
import { refreshErrorHandler } from "../errorHandler/refreshErrorHandler";
import { setUserCookies } from "../setUserCookies/setUserCookies";

let isRefreshingOrAuthing = false;
const timedoutRequestsQueue: [NodeJS.Timeout, (value: unknown) => void][] = [];
const TIMEOUT_REQUEST = 30000;

export interface CheckAccessTokenMiddlewareOptions {
  config: AxiosRequestConfig;
  lang: LanguagesEnum;
  AuthRequireOptions: MutableRefObject<AuthRequireOptionsType | null>;
  authUserByCurrentStatus: (options: AuthRequireOptionsType) => Promise<void>;
}

/**
 *
 * Функция CheckAccessTokenMiddleware представляет собой промежуточный слой для Axios,
 * который проверяет наличие access_token и refresh_token в Cookies. Если access_token отсутствует,
 * а refresh_token присутствует, то происходит запрос на обновление токенов.
 * Если refresh_token также отсутствует, то функция передает параметры для авторизации пользователя.

 * Входные параметры:
 * @param options

 * Выходные параметры:
 * @returns AxiosRequestConfig

 * Также функция имеет переменную isRefreshing, которая отвечает за состояние обновления токенов,
 * и массив timedoutRequestsQueue, который содержит отложенные запросы на обновление токенов.

 * Функция также содержит интерфейс CheckAccessTokenMiddlewareOptions, который определяет
 * типы входных параметров.

 * Для корректной работы функции необходимо передать в нее все входные параметры.
 */

export const CheckAccessTokenMiddleware = async (
  options: CheckAccessTokenMiddlewareOptions
): Promise<AxiosRequestConfig> => {
  const { AuthRequireOptions, config, lang, authUserByCurrentStatus } = options;
  const urlParams = new URLSearchParams(window.location.href);
  const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
  const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
  const code = urlParams.get(CODE_URL_PARAM_NAME);
  AuthRequireOptions.current = {
    lang: lang,
    redirectUrl: window.location.pathname,
    accessToken: accessToken,
    refreshToken: refreshToken,
    code: code,
  };

  if (isRefreshingOrAuthing) {
    await new Promise((resolve) => {
      const timeout = setTimeout(resolve, TIMEOUT_REQUEST);
      timedoutRequestsQueue.push([timeout, resolve]);
    });

    const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
    config.headers["X-Authorization"] = `Bearer ${accessToken}`;
    return config;
  }

  if (!accessToken && refreshToken) {
    try {
      isRefreshingOrAuthing = true;
      const response = await axios.post<
        RefreshResponse,
        AxiosResponse<RefreshResponse>
      >("/api/v1/sso/refresh", {
        refresh_token: refreshToken,
      });

      setUserCookies(response);

      for (const [timeout, resolver] of timedoutRequestsQueue) {
        clearTimeout(timeout);
        resolver(true);
      }

      isRefreshingOrAuthing = false;
    } catch (err: any) {
      refreshErrorHandler(err.response.status);
      await authUserByCurrentStatus(AuthRequireOptions.current);
    }
  }

  if (!refreshToken) {
    isRefreshingOrAuthing = true;
    await authUserByCurrentStatus(AuthRequireOptions.current);
  } else {
    isRefreshingOrAuthing = false;
  }

  config.headers["X-Authorization"] = `Bearer ${accessToken}`;

  return config;
};
