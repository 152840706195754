import getErrorMessage from "@/lib/_common/getErrorMessage";
import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface UpdateStudentDetailsActiveOptions {
    userId: number
    studentDetailsId: number
    degree: IDegree | null
}

export const updateStudentDetailsActive = createAsyncThunk<void, UpdateStudentDetailsActiveOptions, ThunkApiType<string>>(
  "userSlice/updateStudentDetailsActive",
  async (options, thunkAPI) => {
    const { userId, studentDetailsId, degree } = options;
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const body = {
        degree,
        first_time_degree: false,
      };
      await extra.api.put(`/users/${userId}/studentDetails/${studentDetailsId}/assign-active`, body);

    } catch ({ response: error }: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
