import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";
import CloseIcon from "@/public/images/symbols/close.svg";
import clsx from "clsx";

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  id?: string;
  showModal?: boolean;
  closeModal?: () => void;
  className?: string
  type?: "non-closable";
  size?: "normal" | "wide";
  modalRoot?: HTMLElement | null;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = (props) => {
  const { children, id, showModal, closeModal, type, size, modalRoot, className, ...otherProps } = props;

  if (!showModal) {
    return null;
  } else {
    return createPortal(
      <div
        className={clsx(styles.background, className)}
        onClick={() => type !== "non-closable" && closeModal?.()}
        {...otherProps}
      >
        <div
          className={clsx(styles.modal, size === "wide" && styles.modal_wide)}
          id={id ?? "modalId"}
          onClick={(e) => e.stopPropagation()}
        >
          {type !== "non-closable" && (
            <CloseIcon
              className={styles.modal__close}
              id="closeModalBtn"
              onClick={() => closeModal?.()}
            />
          )}
          <div className={styles.modal__flex}>{children}</div>
        </div>
      </div>,
      modalRoot ?? document.body
    );
  }
};
