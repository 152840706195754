import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_KEY } from "../../../App/providers/AuthProvider/lib/constants/constants";
import { getCurrentLanguage } from "@/lib/_common/getCurrentLanguage";

const createAxiosApi = (): AxiosInstance => {
  const ApiHostUrl = "/api/v1";
  const accessToken = Cookies.get(ACCESS_TOKEN_KEY);
  const language = getCurrentLanguage();

  return axios.create({
    baseURL: ApiHostUrl,
    headers: {
      "X-Authorization": `Bearer ${accessToken}`,
      "Accept-Language": language ?? "ru",
    },
  });
};

export const api = createAxiosApi();

