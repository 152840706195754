import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PersonalDataType } from "../../../types/personalStateSchema";
import { PersonalActions } from "../../slice/PersonalSlice";
import { postDataAdapter } from "../../../lib/postDataAdapter";
import { nextPage } from "@Entities/Sidebar";
import getErrorMessage from "@/lib/_common/getErrorMessage";

export interface PostPersonalDataOptions {
  userId: number
  studentDetailsId: number
  data: PersonalDataType
  isWindow: boolean
}

export const PostPersonalData = createAsyncThunk<PersonalDataType, PostPersonalDataOptions, ThunkApiType<string>>(
  "personal/savePersonalData",
  async (options, thunkApi) => {
    const { userId, studentDetailsId, data, isWindow } = options;
    const { extra, rejectWithValue, dispatch } = thunkApi;

    try {
      const postData = postDataAdapter(data);

      await extra.api.post(`/users/${userId}/studentDetails/${studentDetailsId}/forms/personally`, postData);


      if (isWindow) {
        dispatch(PersonalActions.setIsSavedSuccessfull(true));
      } else {
        nextPage(thunkApi);
      }

      return data;
    } catch (err: any) {
      const errorDetail = getErrorMessage(err?.response);
      dispatch(PersonalActions.setPersonalData(data));
      return rejectWithValue(errorDetail);
    }
  }
);
