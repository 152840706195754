import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserActions } from "../../slice/UserSlice";
import getErrorMessage from "@/lib/_common/getErrorMessage";

interface StudentDetailsActiveProps {
    userId: number
}

export const setStudentDetailsActive = createAsyncThunk<IStudentDetails, StudentDetailsActiveProps, ThunkApiType<string>>(
  "user/studentDetailsActive",
  async (data, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;
    const { userId } = data;

    try {
      const response = await extra.api.get(`/users/${userId}/studentDetails/active`);
      const payload = response.data.result;
      const studentDetailsId = payload.id;
      if (studentDetailsId) {
        const { data } = await extra.api.get(
          `/users/${userId}/studentDetails/${studentDetailsId}/application`
        );
        const status = data.result.status;
        dispatch(UserActions.setStatus(status));
      }

      dispatch(UserActions.setStudentDetailsActive(payload));
      dispatch(UserActions.setInited());
      return response.data;
    } catch ({ response: error }: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
