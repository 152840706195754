import { FC, PropsWithChildren, useEffect, useMemo, useState, ChangeEvent } from "react";
import styles from "@/layouts/_Common/ModalItems/Modals.module.scss";
import ButtonCustom from "@/components/_Common/ButtonCustom";
import { Form, Row } from "react-bootstrap";
import useTranslation from "next-translate/useTranslation";
import { Controller, useForm } from "react-hook-form";
import FormSelect from "@/components/_Common/Form/Select";
import FormBorder from "@/components/_Common/Form/Border";
import { useYup } from "@Shared/lib/yup/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { IDictionaryItem } from "@/lib/services/dictionary.service";
import { dataFinancing } from "@/lib/_common/formsConditions";
import { Asserts } from "yup";
import { Modal } from "@Shared/ui/Modal/Modal";
import DegreeEnum from "@/lib/enums/degree";

export type FinancingModalType = {
  showModal: boolean;
  closeModal: () => void;
  name: string;
  setFinancing: (
    id: string | number,
    val: string | any,
    setFinancingLabel: (value: string) => void
  ) => void;
  selectedProgramId: number;
  setFinancingLabel?: (id: string) => void;
  directionCode: string | undefined;
  directionOfEducation?: string;
  competitiveGroupId?: number;
  chooseProgram?: (e: (ChangeEvent<HTMLInputElement> | string), groupId?: number, financing?: string) => Promise<void>;
  isAll?: string;
  budget?: number;
  contract?: number;
  updateChosenPrograms?: () => void;
  slug?: string;
  degree?: string;
};

const FinancingModal: FC<PropsWithChildren<FinancingModalType>> = (props) => {
  const {
    showModal,
    directionOfEducation,
    closeModal,
    name,
    setFinancing,
    competitiveGroupId,
    selectedProgramId,
    setFinancingLabel,
    chooseProgram,
    isAll,
    budget,
    contract,
    updateChosenPrograms,
    slug,
    degree,
  } = props;
  const { t } = useTranslation("modals");
  const [financingOptions, setFinancingOptions] = useState<
    IDictionaryItem[] | null
  >();
  const yup = useYup();
  const emptyDictionaryItem: IDictionaryItem = { label: "", value: "" };
  const financing = useMemo(
    (): IDictionaryItem[] =>
      Object.keys(dataFinancing).map((key: keyof typeof dataFinancing) => ({
        label: t(`financing.${key}`),
        value: key,
      })),
    []
  );

  const schema = yup.object().shape({
    financing: yup.string().required(),
  });

  const { control, clearErrors, formState: { errors }, setValue, watch, handleSubmit } =
    useForm({
      resolver: yupResolver(schema as Asserts<any>),
      mode: "onChange",
    });

  useEffect(() => {
    if (budget && contract) {
      if (isAll === "all") {
        // Если выбраны оба варианта финансирования и выбрана опция "Все", показать все варианты финансирования
        setFinancingOptions(financing);
      } else {
        // Если выбраны оба варианта финансирования, но не выбрана опция "Все", исключить "budget_contract"
        setFinancingOptions(financing.filter(item => item.value !== "budget_contract"));
      }
    } else if (budget) {
      // Если выбран только вариант финансирования "Бюджет"
      setFinancingOptions(financing.filter(item => item.value === "budget"));
    } else if (contract) {
      // Если выбран только вариант финансирования "Контракт"
      setFinancingOptions(financing.filter(item => item.value === "contract"));
    }
  }, []);

  const watchFinancing = watch("financing");

  const onSubmit = async (data: IPrograms) => {
    if (isAll === "all" && chooseProgram) {
      chooseProgram("_", competitiveGroupId, watchFinancing ?? data.financing);
      closeModal();
    } else {
      setFinancingLabel &&
        setFinancingLabel(
          financingOptions?.find(
            (item: IDictionaryItem) => item?.value === watchFinancing
          )?.label || "Выбрать"
        );
      if (watchFinancing && selectedProgramId) {
        if (financingOptions) {
          setFinancing(
            selectedProgramId,
            financingOptions.find((item) => {
              return item.value === data.financing;
            })?.value || emptyDictionaryItem.value,
            setFinancingLabel as (value: string) => void
          );
        }
        closeModal();
      }
    }
    if (updateChosenPrograms) await updateChosenPrograms();
  };

  const nameModal = "financingModal";

  useEffect(() => {
    if ((slug === "ai" && degree === DegreeEnum.BACHELOR) || name === "AI360: ML Native") {
      setFinancingOptions(financing.filter(item => item.value === "budget"));
    }
  }, []);

  return (
    <Modal closeModal={closeModal} showModal={showModal}>
      <Form
        className={styles.modalContainer}
        id={nameModal}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className={styles.title}>{name}</h2>
        <span className={styles.paragraph__small}>{directionOfEducation}</span>
        <div className={styles.select}>
          <Controller
            control={control}
            defaultValue=""
            name="financing"
            render={() => (
              <FormSelect
                clearErrors={clearErrors}
                id={`${nameModal}_select`}
                isInvalid={!!errors.financing}
                name="financing"
                options={financingOptions as IDictionaryItem[]}
                placeholder={t("financing.placeholder")}
                setValue={setValue}
              />
            )}
          />
          {errors.financing && (
            <Form.Control.Feedback type="invalid">
              {errors.financing?.message as string}
            </Form.Control.Feedback>
          )}
        </div>

        <Row>
          <ButtonCustom
            className={styles.button}
            id={`${nameModal}_btnCancel`}
            rounded={true}
            variant="outline-primary"
            onClick={closeModal}
          >
            {t("financing.button_left")}
          </ButtonCustom>
          <ButtonCustom
            className={styles.button}
            id={`${nameModal}_btnSubmit`}
            rounded={true}
            type="submit"
            variant="primary"
          >
            {t("financing.button_right")}
          </ButtonCustom>
        </Row>

        {watchFinancing === "budget" && (
          <>
            <FormBorder />
            <p className={styles["paragraph__small-financing"]}>
              <b>{t("financing.hint.label")} </b>
              {t("financing.hint.text")}
            </p>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default FinancingModal;
