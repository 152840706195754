import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { localCitizenship } from "@/lib/_common/formsConditions";
import EducationBuildingEnum from "@/lib/enums/educationBuilding";
import { IDictionaryItem } from "@/lib/services/dictionary.service";
import { getCurrentLanguage } from "@/lib/_common/getCurrentLanguage";

const apiName = "DictionariesApi";
const baseUrl = "/api/v1/dictionaries";

type BaseResponse = {
  ok: boolean;
  message: string;
};

type WithResultResponse<T> = BaseResponse & {
  result: T;
};

type ItemsResponse<T> = BaseResponse & {
  result: {
    items: T[];
  };
};

type DictionariesResponse = ItemsResponse<MapItem>;

export interface AchievementResponse extends BaseResponse {
  result: AchievementsResult;
}

export interface AchievementsResult {
  items: AchievementItem[];
  meta_data: any[];
}

export interface AchievementItem {
  title: string;
  id: number;
  category: string;
}

type DictionariesDepreceationResponse = BaseResponse & {
  result: MapItem[];
};

const transformResponse = (
  response: DictionariesResponse | DictionariesDepreceationResponse,
  customErrorMessage?: string
) => {
  if (!response.ok) {
    throw new Error(customErrorMessage || "Ошибка при запросе данных");
  }

  return "items" in response.result ? response.result.items : response.result;
};

const createQueryEndpoint = <TArgs = void>(
  url: string | ((args: TArgs) => string),
  transformResponseFn: (response: WithResultResponse<any>) => any = transformResponse
) => ({
    query: (args: TArgs) => (typeof url === "function" ? url(args) : url),
    transformResponse: (response: WithResultResponse<any>) => transformResponseFn(response),
  });
export type DictionariesApiReducerType = ReturnType<typeof dictionariesApi.reducer>;

export const dictionariesApi = createApi({
  reducerPath: apiName,
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const lang = getCurrentLanguage();

      headers.set("Accept-Language", lang);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCountries: builder.query(
      createQueryEndpoint<void>("/countries")
    ),
    fetchDocumentTypes: builder.query<{ value: string; label: string; }[], boolean>({
      query: (isForeigner) => `/person_id_types/${!isForeigner ? "RF" : "foreigner"}`,
      transformResponse: (response: DictionariesResponse) => transformResponse(response),
    }),
    fetchUniversityRegions: builder.query(
      createQueryEndpoint<void>("/university_regions")
    ),
    fetchDocumentOptionsByCountry: builder.query(
      createQueryEndpoint<{ country: string | undefined; afterOrBefore: boolean, degree?: string}>(
        ({ country, afterOrBefore, degree }) => {
          return country === localCitizenship || country === undefined
            ? `/education_document_types_rf/${degree}/${afterOrBefore ? "before_2014" : "after_2014"}`
            : `/education_document_types_foreigner/${degree}`;
        }
      )
    ),
    fetchDivisionCode: builder.query(
      createQueryEndpoint<{code: string}>(
        ({ code }) => `/division_codes?code=${code}`)
    ),
    fetchConfirmingSubjects: builder.query(
      createQueryEndpoint(({
        olympiadTypeId,
        profile,
        diploma,
        year,
      }) => `/confirming_subjects/${olympiadTypeId}/${profile}/${diploma}/${year}`),
    ),
    fetchUniversityCityOptions: builder.query(
      createQueryEndpoint<{ regionId: number, type:  EducationBuildingEnum }>(
        ({ regionId, type }) => `/university_cities/${regionId}/${type}`
      )
    ),
    fetchUniversityCities: builder.query(
      createQueryEndpoint<{ region: string, type: string }>(
        ({ region, type }) => `/university_cities/${region}/${type}`
      )
    ),
    fetchSchoolOptions: builder.query(
      createQueryEndpoint<{ city: number; type: EducationBuildingEnum.SCHOOLS | EducationBuildingEnum.UNIVERSITIES }>(
        ({ city, type }) => `/universities/${city}/${type}`
      )
    ),
    fetchExamDisciplines: builder.query(
      createQueryEndpoint<{ degree: IDegree, year: number, isSpo: boolean }>(
        ({ degree, year, isSpo }) => `/disciplines_with_programs/${degree}/${year}?is_spo=${isSpo}`
      )
    ),
    fetchAllExamDisciplines: builder.query(
      createQueryEndpoint(
        ({ degree }) => `/disciplines/${degree}?all=true`
      )
    ),
    fetchTypeOptions: builder.query(
      createQueryEndpoint<{ specializedEducation?: boolean; belarusians: boolean }>(
        ({ specializedEducation = false, belarusians }) =>
          `/exam_types?has_secondary_specialized_education=${specializedEducation}&for_belarusians=${belarusians}`
      )
    ),
    fetchSpecialtyExams: builder.query(
      createQueryEndpoint<{ programId: number; disciplineId: number }>(
        ({ programId, disciplineId }) =>
          `/program/${programId}/specialty_exams${disciplineId ? `?discipline_id=${disciplineId}` : ""}`
      )
    ),
    fetchLanguageExamDates: builder.query(
      createQueryEndpoint<void>("/languages/english/language_exams")
    ),
    fetchEnterpriseTypes: builder.query(
      createQueryEndpoint<void>("/enterprise_types")
    ),
    fetchRelativeDegreeOptions: builder.query(
      createQueryEndpoint<void>("/relationship_degrees")
    ),
    fetchOlympiadTypes: builder.query(
      createQueryEndpoint<void>("/olympiad_types")
    ),
    fetchAchievements: builder.query<AchievementItem[] | MapItem[], { degree: IDegree | string, year: number }>({
      query: ({ degree, year }) => `/achievement_types/${degree}/${year}`,
      transformResponse: (response: AchievementResponse) => {
        if (!response.ok) {
          throw new Error("Ошибка при получении достижений");
        }
        return response.result.items;
      },
    }),
    fetchAchievementsWithCategory: builder.query<AchievementItem[], { degree: IDegree | string, year: number }>({
      query: ({ degree, year }) => `/achievement_types_with_category/${degree}/${year}`,
      transformResponse: (response: AchievementResponse) => {
        if (!response.ok) {
          throw new Error("Ошибка при получении достижений");
        }
        return response.result.items;
      },
    }),
    fetchSpecialOptions: builder.query(
      createQueryEndpoint<void>("/special_conditions")
    ),
    fetchFromWhereOptions: builder.query(
      createQueryEndpoint<void>("/itmo_achievement/from_where")
    ),
    fetchDirections: builder.query<IDictionaryItem[], { lang: string; degree: IDegree }>({
      query: ({ lang, degree }) => ({
        url: `/directions_on_site/${degree}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      transformResponse: (response: WithResultResponse<any>) => {
        if (response.result?.items?.length !== 0) {
          return response.result.items as IDictionaryItem[];
        }
        return [];
      },
    }),
    fetchMilitaryCategories: builder.query(
      createQueryEndpoint<void>("/military_categories")
    ),
    fetchPrivileges: builder.query(
      createQueryEndpoint<void>("/privileges")
    ),
    fetchAdvantages: builder.query(
      createQueryEndpoint<void>("/advantages")
    ),
    fetchSpecialConditions: builder.query(
      createQueryEndpoint<void>("/special_conditions")
    ),
    fetchAdmissionConditions: builder.query(
      createQueryEndpoint(
        ({ degree, financing }) => `/admission_conditions/${degree}/${financing}`
      )
    ),
    fetchLanguages: builder.query(
      createQueryEndpoint<void>("/languages")
    ),
    fetchDateLanguages: builder.query(
      createQueryEndpoint<void>("/languages/english/language_exams")
    ),
    fetchFaculties: builder.query<IDictionaryItem[], { lang: string; degree: IDegree | string }>({
      query: ({ lang, degree }) => ({
        url: `/faculties/${degree}`,
        headers: {
          "Accept-Language": lang,
        },
      }),
      transformResponse: (response: WithResultResponse<any>) => {
        if (response.result?.items?.length !== 0) {
          return response.result.items as IDictionaryItem[];
        }
        return [];
      },
    }),
    fetchLeaders: builder.query(
      createQueryEndpoint<{ facultyId: number, programId: number }>(({ facultyId, programId }) =>
        `/faculty/${facultyId}/program/${programId}/persons`)
    ),
    fetchCompetitiveGroupDict: builder.query(
      createQueryEndpoint(({ competitiveGroupId }) => `/competitive_groups/${competitiveGroupId}/programs`)
    ),
  }),
});

export const {
  useGetCountriesQuery,
  useFetchDocumentTypesQuery,
  useFetchUniversityRegionsQuery,
  useFetchDocumentOptionsByCountryQuery,
  useFetchUniversityCityOptionsQuery,
  useFetchSchoolOptionsQuery,
  useFetchExamDisciplinesQuery,
  useFetchAllExamDisciplinesQuery,
  useFetchTypeOptionsQuery,
  useFetchLanguageExamDatesQuery,
  useFetchEnterpriseTypesQuery,
  useFetchRelativeDegreeOptionsQuery,
  useFetchOlympiadTypesQuery,
  useFetchAchievementsQuery,
  useFetchAchievementsWithCategoryQuery,
  useFetchSpecialOptionsQuery,
  useFetchFromWhereOptionsQuery,
  useFetchDirectionsQuery,
  useFetchMilitaryCategoriesQuery,
  useFetchPrivilegesQuery,
  useFetchAdvantagesQuery,
  useFetchSpecialConditionsQuery,
  useFetchConfirmingSubjectsQuery,
  useFetchAdmissionConditionsQuery,
  useFetchUniversityCitiesQuery,
  useFetchSpecialtyExamsQuery,
  useFetchLanguagesQuery,
  useFetchDateLanguagesQuery,
  useFetchFacultiesQuery,
  useFetchLeadersQuery,
  useFetchCompetitiveGroupDictQuery,
  useFetchDivisionCodeQuery,
} = dictionariesApi;
