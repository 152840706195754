import { FC } from "react";
import clsx from "clsx";

import styles from "./Skeleton.module.scss";

interface SkeletonProps {
  className?: string;
  rounded?: boolean;
}

export const Skeleton: FC<SkeletonProps> = (props) => {
  const { className, rounded = false } = props;

  return (
    <div
      id="skeleton"
      className={clsx(
        styles.skeleton,
        { [styles.rounded]: rounded },
        className
      )}
    />
  );
};
