import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import styles from "./CreateStatement.module.scss";
import Link from "next/link";
import { Col, Form } from "react-bootstrap";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { getUserDegree, getUserTheme } from "@Entities/User";
import FirstStep from "@/public/images/symbols/first-step.svg";
import SecondStep from "@/public/images/symbols/second-step.svg";
import ThirdStep from "@/public/images/symbols/third-step.svg";
import { ThemeEnum } from "@Shared/types/themeTypes";
import DegreeEnum from "@/lib/enums/degree";

export const CreateStatement: FC = () => {
  const { t } = useTranslation("home");

  const degree = useSelector(getUserDegree);
  const theme = useSelector(getUserTheme);

  const accessibleClasses: Record<string, boolean> = {
    [styles.steps__item_accessible]: theme === ThemeEnum.ACCESSIBLE,
  };

  return (
    <>
      <h2 className={styles.heading}>
        {t("steps.heading")}
      </h2>
      <div className={styles.steps} id="statementSteps">
        <Link
          className={styles.steps__link}
          href='/programs'
          id="programsStep_link"
        >
          <Form.Group
            as={Col}
            className={clsx(styles.steps__item, styles.steps__item_active, accessibleClasses)}
            id="programsStep"
            xl={4}
          >
            <div className={styles.steps__item_one}>
              <div className={styles.steps__item__icon}>
                <FirstStep />
              </div>
              <h3>{t("steps.programs.label")}</h3>
              <p>
                {degree === DegreeEnum.POSTGRADUATE ? (
                  t("steps.programs.descriptionPostgraduate")
                ) : (
                  t("steps.programs.description")
                )}
              </p>
            </div>
          </Form.Group>
        </Link>
        <Link
          className={styles.steps__link}
          href='/personal/personally'
          id="personallyStep_link"
        >
          <Form.Group
            as={Col}
            className={clsx(styles.steps__item, styles.steps__item_active, accessibleClasses)}
            id="personallyStep"
            xl={4}
          >
            <div className={styles.steps__item_two}>
              <div className={styles.steps__item__icon}>
                <SecondStep />
              </div>
              <h3>{t("steps.application.label")}</h3>
              <p>{t("steps.application.description")}</p>
            </div>
          </Form.Group>
        </Link>
        <Link
          className={styles.steps__link}
          href='/personal/documents'
          id="documentsStep_link"
        >
          <Form.Group
            as={Col}
            className={clsx(styles.steps__item, accessibleClasses)}
            id="documentsStep"
            xl={4}
          >
            <div className={styles.steps__item_three}>
              <div className={styles.steps__item__icon}>
                <ThirdStep />
              </div>
              <h3>{t("steps.documents.label")}</h3>
              <p>{t("steps.documents.description")}</p>
            </div>
          </Form.Group>
        </Link>
      </div>
    </>
  );
};
