import { FC, PropsWithChildren } from "react";
import { Container } from "react-bootstrap";
import styles from "./ProgramsModal.module.scss";
import useTranslation from "next-translate/useTranslation";
import clsx from "clsx";
import FavoriteIcon from "@/public/images/symbols/favorite.svg";
import { useRouter } from "next/router";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import globalRoutes from "@/lib/routes";
import useQuestionnaire from "@/hooks/useQuestionnaire";
import { useUrlParams } from "@/hooks/useUrlParams";

type ProgramsModalProps = {
  showModal: boolean;
  closeModal: () => void;
};

const ProgramsModal: FC<PropsWithChildren<ProgramsModalProps>> = (
  props
): JSX.Element => {
  const { closeModal, showModal } = props;
  const { chosenPrograms } = useTypedSelector((state) => state.form);

  const { active } = useTypedSelector((state) => state.window) as {
    active: { id: number };
  };
  const { user, studentDetails: studentDetailActive } = useQuestionnaire();

  const { t } = useTranslation("modals");
  const router = useRouter();
  const {
    queryParams: { degree },
  } = useUrlParams<{
    degree: IDegree;
  }>();

  const maxProgramsAllowed = 4;

  const onGoNextButonClick = () => {
    if (active) {
      return router.push(
        globalRoutes
          .window(degree)
          .questionnaire(user.id, studentDetailActive.id).programs.to
      );
    }

    return router.push(globalRoutes.personal.programs);
  };

  return (
    <div
      className={clsx(styles.modal, showModal && styles.modal_active)}
      id="programsModalActiveWrapper"
    >
      <Container className={styles.modal__container}>
        <div className={styles.modal__column}>
          <div className={styles.modal__icon}>
            <FavoriteIcon />
          </div>
          <div className={styles.modal__text}>
            <p>
              {t("programs.selected")}
              <b>{chosenPrograms?.length ?? 0}</b>
            </p>
            <p>
              ({t("programs.maximum")}{" "}
              <b>
                {studentDetailActive.degree !== "postgraduate"
                  ? maxProgramsAllowed
                  : 6}
              </b>
              )
            </p>
          </div>
        </div>
        <div className={styles.modal__column}>
          <div
            className={clsx(styles.modal__button, styles.modal__button_dark)}
            id="programsModalActive_nextBtn"
            onClick={onGoNextButonClick}
          >
            {t("programs.next")}
          </div>
          <div
            className={clsx(styles.modal__button, styles.modal__button_light)}
            id="programsModalActive_hideBtn"
            onClick={closeModal}
          >
            {t("programs.hide")}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProgramsModal;
