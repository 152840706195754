import FormLabel from "@/components/_Common/Form/Label";
import inputDateHandle from "@/lib/_common/inputs/inputDateHandle";
import { MimeType } from "@Shared/ui/InputFile/types";
import useTranslation from "next-translate/useTranslation";
import { ClipboardEvent, FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { ValidFieldNames } from "../../PersonalFormAll/PersonalFormAll";
import pasteDateHandle from "@/lib/_common/inputs/pasteDateHandle";
import { PersonalDataType } from "@Features/PersonalForm/types/personalStateSchema";
import { InputFile } from "@Shared/ui/InputFile";

interface ShelterCertificateFormProps {
  disabled?: boolean;
}

export const ShelterCertificateForm: FC<ShelterCertificateFormProps> = (
  props
) => {
  const { disabled = false } = props;

  const { t } = useTranslation("personal/personally");

  const { control, formState, trigger } = useFormContext<PersonalDataType>();

  const handlePasteDate = (e: ClipboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLElement;
    const name = target.dataset.name as ValidFieldNames;

    if (name) {
      pasteDateHandle(e);
      trigger(name);
    }
  };

  return (
    <>
      <Row>
        <Form.Group as={Col} id="personIdSeriesGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.series.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.shelterCertificate.series"
            render={({ field }) => (
              <Form.Control
                disabled={disabled}
                id="personIdSeries"
                maxLength={2}
                placeholder="XX"
                type="text"
                value={field.value}
                onChange={field.onChange}
              />
            )}
            rules={{
              deps: ["documents.personId.type.value"],
            }}
          />
          {formState.errors.documents?.personId?.shelterCertificate?.series && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId?.shelterCertificate?.series
                  .message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="personIdNumberGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.number.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.shelterCertificate.number"
            render={({ field }) => (
              <Form.Control
                disabled={disabled}
                id="personIdNumber"
                maxLength={7}
                placeholder="XXXXXXX"
                type="text"
                value={field.value}
                onChange={field.onChange}
              />
            )}
            rules={{
              deps: ["documents.personId.type.value"],
            }}
          />
          {formState.errors.documents?.personId?.shelterCertificate?.number && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId.shelterCertificate.number
                  .message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="personIdIssuedDateGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.issuedDate.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.shelterCertificate.issuedDate"
            render={({ field }) => (
              <Form.Control
                data-name={field.name}
                disabled={disabled}
                id="personIdIssueDate"
                maxLength={10}
                type="text"
                value={field.value}
                placeholder={t(
                  "form.documents.personId.issuedDate.placeholder"
                )}
                onChange={field.onChange}
                onInput={inputDateHandle}
                onPaste={handlePasteDate}
              />
            )}
            rules={{
              deps: ["documents.personId.type.value"],
            }}
          />
          {formState.errors.documents?.personId?.shelterCertificate
            ?.issuedDate && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId.shelterCertificate
                  .issuedDate.message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} id="personIdIssuedDateGroup" md={6}>
          <FormLabel required>
            {t("form.documents.personId.validityPeriod.label")}
          </FormLabel>
          <Controller
            control={control}
            name="documents.personId.shelterCertificate.validityPeriod"
            render={({ field }) => (
              <Form.Control
                data-name={field.name}
                disabled={disabled}
                id="personIdIssueDate"
                maxLength={10}
                type="text"
                value={field.value}
                placeholder={t(
                  "form.documents.personId.validityPeriod.placeholder"
                )}
                onChange={field.onChange}
                onInput={inputDateHandle}
                onPaste={handlePasteDate}
              />
            )}
            rules={{
              deps: ["documents.personId.type.value"],
            }}
          />
          {formState.errors.documents?.personId?.shelterCertificate
            ?.validityPeriod && (
            <Form.Control.Feedback type="invalid">
              {
                formState.errors.documents?.personId.shelterCertificate
                  .validityPeriod.message
              }
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>
      <Form.Group id="scansGroup">
        <FormLabel required>
          {t("form.documents.personId.scans.label")}
        </FormLabel>
        <Controller
          control={control}
          defaultValue={undefined}
          name="documents.personId.shelterCertificate.scans"
          render={({ field }) => (
            <InputFile
              category="passport"
              formats={[MimeType.Pdf, MimeType.Jpeg, MimeType.Png]}
              hint={t("form.documents.personId.scans.hint")}
              id="personIdScans"
              maxNumFiles={5}
              maxSize={10}
              placeholder={t("form.documents.personId.scans.placeholder")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
          rules={{
            deps: ["documents.personId.type.value"],
          }}
        />
        {formState.errors.documents?.personId?.shelterCertificate?.scans && (
          <Form.Control.Feedback type="invalid">
            {
              (
                formState.errors.documents?.personId?.shelterCertificate
                  .scans as any
              )?.message
            }
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};
