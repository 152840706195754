import { postBachelorExams } from "@Features/ExamsForm/BachelorExamsForm/model/services/postBachelorExams";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  NotificationAction,
  NotificationActionEnum,
  NotificationType,
} from "../../types/notificationSchema";
import { postPostgraduateExams } from "@Features/ExamsForm/PostgraduateExamsForm/model/services/postPostgraduateExams";
import { fetchBachelorExams } from "@Features/ExamsForm/BachelorExamsForm";
import { postMasterExams } from "@Features/ExamsForm/MasterExamsForm/model/services/PostMasterExams";

const initialState: NotificationType | null = null;

export function getNotificationReducer({
  type,
  payload,
}: NotificationAction): NotificationType | null {
  const notification: {
    [key in NotificationActionEnum]: NotificationType | null;
  } = {
    [NotificationActionEnum.ERROR]: {
      title: "Ошибка",
      text: !!payload ? payload : "Ошибка сохранения данных",
      variant: "error",
    },
    [NotificationActionEnum.SUCCESS]: {
      title: "Успешно",
      text: !!payload ? payload : "Данные успешно сохранены",
      variant: "success",
    },
    [NotificationActionEnum.RESET]: null,
  };

  return notification[type] ?? notification[NotificationActionEnum.RESET];
}

const fulfilledMatcher = isAnyOf();

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotification: () => initialState,
    setError: (_: NotificationType | null, { payload }) =>
      getNotificationReducer({ type: NotificationActionEnum.ERROR, payload }),
    setSuccess: (_: NotificationType | null, { payload }) =>
      getNotificationReducer({ type: NotificationActionEnum.SUCCESS, payload }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          postBachelorExams.pending,
          fetchBachelorExams.pending,
          postMasterExams.pending,
          postPostgraduateExams.pending
        ),
        () => initialState
      )
      .addMatcher(
        isAnyOf(
          postBachelorExams.rejected,
          fetchBachelorExams.rejected,
          postMasterExams.rejected,
          postPostgraduateExams.rejected
        ),
        (_, { payload }) =>
          getNotificationReducer({
            type: NotificationActionEnum.ERROR,
            payload,
          })
      )
      .addMatcher(fulfilledMatcher, (_, { payload }) =>
        getNotificationReducer({
          type: NotificationActionEnum.SUCCESS,
          payload,
        })
      );
  },
});

export const { reducer: notificationReducer } = notificationSlice;
export const { actions: notificationActions } = notificationSlice;
