import { localCitizenship } from "@/lib/_common/formsConditions";
import { YupAppType } from "@Shared/lib/yup/yup-extended";
import DocumentTypesEnum from "./documentTypesEnum";
import { DivisionCodeCorrect } from "../types/personalStateSchema";

interface PersonalValidationOptions {
  yup: YupAppType;
}

export const createPersonalSchema = (
  options: PersonalValidationOptions
): any => {
  const { yup } = options;

  return yup.object({
    citizenship: yup.mixed().required(),
    fullName: yup.object({
      firstName: yup.string().nameValid().max(225).required(),
      lastName: yup.string().nameValid().max(225).required(),
      middleName: yup.string().nameValid().max(225).notRequired(),
    }),
    isHaveOldName: yup.boolean().notRequired(),
    fullNameOld: yup.object().when("isHaveOldName", {
      is: true,
      then: yup.object({
        firstName: yup.string().nameValid().max(225).required(),
        lastName: yup.string().nameValid().max(225).required(),
        middleName: yup.string().nameValid().max(225).notRequired(),
      }),
      otherwise: yup.object({
        firstName: yup.string().notRequired(),
        lastName: yup.string().notRequired(),
        middleName: yup.string().notRequired(),
      }),
    }),
    fullNameOldDate: yup.mixed().when("isHaveOldName", {
      is: true,
      then: yup.string().required().correctDateFormat().minDate().maxDate(),
      otherwise: yup.string().notRequired(),
    }),
    contact: yup.object({
      email: yup.string().min(1).max(225).email().required(),
      phone: yup.string().nullable().min(10).required(),
    }),
    birthDate: yup
      .string()
      .required()
      .correctDateFormat()
      .minDate()
      .maxDate(),
    birthPlace: yup.string().nullable().min(1).max(200).required(),
    gender: yup.string().nullable().required(),
    documents: yup.object({
      personId: yup.object({
        type: yup.mixed().required(),

        passport: yup.object().when("type.value", {
          is: DocumentTypesEnum.Passport,
          then: yup.object({
            series: yup.string().onlyDigits().required().min(4),
            number: yup.string().onlyDigits().required().min(6),
            issuedDate: yup
              .string()
              .required()
              .correctDateFormat()
              .minDate()
              .maxDate(),
            divisionName: yup.string().required().min(10).max(255),
            scans: yup.array().min(1).required(),
          }),
        }),

        internationalPassport: yup.object().when("type.value", {
          is: DocumentTypesEnum.InternationalPassport,
          then: yup.object({
            series: yup.string().onlyDigits().required().min(2),
            number: yup.string().onlyDigits().required().min(7),
            issuedDate: yup
              .string()
              .required()
              .correctDateFormat()
              .minDate()
              .maxDate(),
            validityPeriod: yup
              .string()
              .correctDateFormat()
              .minDate(new Date().toISOString())
              .required(),
            divisionName: yup.string().required().min(8).max(30),
            scans: yup.array().min(1).required(),
          }),
        }),

        birthCertificate: yup.object().when("type.value", {
          is: DocumentTypesEnum.BirthCertificate,
          then: yup.object({
            series: yup.string().onlyLetters().required().min(3).max(10),
            number: yup.string().onlyDigits().required().min(6),
            issuedDate: yup
              .string()
              .required()
              .correctDateFormat()
              .minDate()
              .maxDate(),
            scans: yup.array().min(1).required(),
          }),
        }),

        shelterCertificate: yup.object().when("type.value", {
          is: DocumentTypesEnum.ShelterCertificate,
          then: yup.object({
            series: yup.string().onlyCyrillic().required().min(2),
            number: yup.string().onlyDigits().required().min(7),
            issuedDate: yup
              .string()
              .required()
              .correctDateFormat()
              .minDate()
              .maxDate(),
            validityPeriod: yup
              .string()
              .correctDateFormat()
              .minDate(new Date().toISOString())
              .required(),
            scans: yup.array().min(1).required(),
          }),
        }),

        military: yup.object().when("type.value", {
          is: DocumentTypesEnum.MilitaryIdReserve,
          then: yup.object({
            scans: yup.array().min(1).required(),
          }),
        }),

        foreignPassport: yup.object().when("type.value", {
          is: DocumentTypesEnum.ForeignPassport,
          then: yup.object({
            number: yup.string().required().min(6).max(30),
            issuedDate: yup
              .string()
              .required()
              .correctDateFormat()
              .minDate()
              .maxDate(),
            validityPeriod: yup
              .string()
              .correctDateFormat()
              .minDate(new Date().toISOString())
              .required(),
            divisionName: yup.string().required(),
            scans: yup.array().min(1).required(),
            translateScans: yup.array().min(1).required(),
          }),
        }),

        IDCard: yup.object().when("type.value", {
          is: DocumentTypesEnum.IDCard,
          then: yup.object({
            number: yup.string().required().min(6).max(30),
            issuedDate: yup
              .string()
              .required()
              .correctDateFormat()
              .minDate()
              .maxDate(),
            validityPeriod: yup
              .string()
              .correctDateFormat()
              .minDate(new Date().toISOString())
              .required(),
            divisionName: yup.string().required(),
            scans: yup.array().min(1).required(),
            translateScans: yup.array().min(1).required(),
          }),
        }),
      }),
      isHaveOldPassport: yup.boolean(),
      passportOld: yup.object().when("isHaveOldPassport", {
        is: true,
        then: yup.object({
          series: yup.string().required(),
          number: yup.string().required(),
        }),
      }),
    }),
    divisionCorrect: yup.mixed().when([], {
      is: (val: DivisionCodeCorrect[]) => !val?.length,
      then: yup.array().nullable().min(1, "Введите корректный код подразделения").required(),
      otherwise: yup.array().notRequired(),
    }),
    divisionCode: yup.string().when("documents.personId.type.value", {
      is: DocumentTypesEnum.Passport,
      then: yup.string().required().min(7),
    }),
    snils: yup.mixed().when("citizenship.value", {
      is: localCitizenship,
      then: yup.object({
        number: yup.string().min(14).required(),
        scans: yup.array().min(1),
      }),
    }),
  });
};
