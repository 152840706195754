import { FC, ReactNode, useEffect } from "react";
import Head from "next/head";
import styles from "./Default.module.scss";
import useTranslation from "next-translate/useTranslation";
import Footer from "@Entities/Footer/Footer";
import HeaderAccessible from "@Entities/Header/ui/HeaderAccessible/HeaderAccessible";
import clsx from "clsx";
import accessibleModeHandler from "@/lib/themes/accessibleModeHandler";
import clearThemeVariables from "@/lib/themes/clearThemeVariables";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedPrograms, setChosenPrograms } from "@Entities/Form";
import {
  getStudentDetailsActiveUserId,
  getUserAccessableSettings,
  getUserId,
  getUserTheme,
} from "@Entities/User";
import { Header } from "@Entities/Header";
import { ThemeEnum } from "@Shared/types/themeTypes";
import { Loader } from "@Entities/Loader";

interface PersonalDefaultLayoutProps {
  children: ReactNode;
  className?: "form_main";
  isWindow?: boolean;
  isQuestionnaire?: boolean;
}

export const DefaultLayout: FC<PersonalDefaultLayoutProps> = (props) => {
  const {
    children,
    className,
    isWindow = false,
    isQuestionnaire = false,
  } = props;

  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const studentDetailsId = useSelector(getStudentDetailsActiveUserId);
  const chousenPrograms = useSelector(getSelectedPrograms);
  const theme = useSelector(getUserTheme);
  const accessibleSettings = useSelector(getUserAccessableSettings);

  useEffect(() => {
    if (!chousenPrograms && !isWindow && studentDetailsId) {
      dispatch(setChosenPrograms({ userId, studentDetailsId }));
    }

    if (theme === ThemeEnum.ACCESSIBLE) {
      accessibleModeHandler(theme, accessibleSettings);
    } else {
      clearThemeVariables();
    }
  }, [theme, accessibleSettings, studentDetailsId]);

  return (
    <>
      <Head>
        <title>{t(`head.title${isWindow ? "_window" : ""}`)}</title>
        <link href="/favicon/favicon.ico" rel="icon" />
        <meta content="bfc38e6099487cfe" name="yandex-verification" />
        {/* чтобы в safari при фокусе инпута не было автозума */}
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1"
          name="viewport"
        ></meta>
      </Head>

      <div className={styles.layout}>
        {theme === "accessible" && <HeaderAccessible />}
        <Header isQuestionnaire={isQuestionnaire} isWindow={isWindow} />

        <Loader />

        <main
          id="main-content"
          className={clsx(
            className ? styles[className] : styles.form,
            theme === "accessible" && styles.accessible
          )}
        >
          {children}
        </main>

        <Footer />
      </div>
    </>
  );
};
