import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from "./AsyncGarSelect.module.scss";
import { Form } from "react-bootstrap";
import FormLabel from "@/components/_Common/Form/Label";
import { Select } from "@Shared/ui/Select";
import { FetchGarOptions, GarRequestsEnum, RequestDataTypes } from "../types/garSelectTypes";
import { getGarRegionsWithLimit } from "../api/getGarRegionsWithLimit/getGarRegionsWithLimit";
import { getGarDistrictsWithLimit } from "../api/getGarDistrictsWithLimit/getGarDistrictsWithLimit";
import { getGarCitiesWithLimit } from "../api/getGarCitiesWithLimit/getGarCitiesWithLimit";
import { getGarStreetsWithLimit } from "../api/getGarStreetsWithLimit/getGarStreetsWithLimit";
import { getGarHousesWithLimit } from "../api/getGarHousesWithLimit/getGarHousesWithLimit";
import { ResponseReturnType } from "../types/garReqyestTypes";
import debounce from "lodash.debounce";
import { useGetCountriesQuery } from "@/store/rtk/dictionariesApi";

interface AsyncGarSelectProps {
  value: MapItem<number | string> | null
  onChange: (value: MapItem<number | string> | null) => void
  requestDataType: RequestDataTypes
  id?: string
  label?: string
  className?: string
  required?: boolean
  placeholder?: string
  limit?: number
  defaultValue?: string
  regionId?: number | null
  districtId?: number
  cityId?: number
  streetId?: number
  disabled?: boolean
  error?: string
}

export const AsyncGarSelect: FC<AsyncGarSelectProps> = (props) => {
  const {
    id,
    value,
    onChange,
    regionId,
    districtId,
    cityId,
    streetId,
    requestDataType,
    limit = 10,
    placeholder,
    label,
    className,
    required = false,
    disabled = false,
    error = "",
  } = props;

  const [page, setPage] = useState<number>(1);
  const [responseData, setResponseData] = useState<MapItem<number | string>[]>([]);
  const targetInput = useRef<boolean>(false);
  const totalOptions = useRef<number>(9999);
  const isFetching = useRef<boolean>(false);
  const unicOptionsValue = useRef<string[]>([]);

  const mods: Record<string, boolean> = {
    [styles.error]: !!error,
  };

  const handleInitialResponse = (response: ResponseReturnType) => {
    setPage(2);
    setResponseData(response.options);
    totalOptions.current = response.total;
    unicOptionsValue.current = [];
  };

  const Initialize = async (value?: string) => {
    const options: FetchGarOptions = {
      regionId,
      districtId,
      cityId,
      streetId,
      value,
      page: 1,
      limit,
    };

    switch (requestDataType) {
      case GarRequestsEnum.Regions:
        return await getGarRegionsWithLimit(options)
          .then(handleInitialResponse);
      case GarRequestsEnum.Districts:
        if (regionId) {
          return await getGarDistrictsWithLimit(options)
            .then(handleInitialResponse);
        }
      case GarRequestsEnum.Cities:
        if (regionId) {
          return await getGarCitiesWithLimit(options)
            .then(handleInitialResponse);
        }
      case GarRequestsEnum.Streets:
        if (regionId) {
          return await getGarStreetsWithLimit(options)
            .then(handleInitialResponse);
        }
      case GarRequestsEnum.Houses:
        if (regionId && cityId) {
          return await getGarHousesWithLimit(options)
            .then(handleInitialResponse);
        }
    }
    targetInput.current = false;
  };

  const handleInputValue = (value: string) => {
    if (!value) {
      onChange(null);
    }
    Initialize(value);
  };

  const handleResponseAfterScroll = (response: ResponseReturnType) => {
    let isDublicateResponse = false;
    response.options.forEach((option) => {
      const isAlreadyInOptions = unicOptionsValue.current.includes(option.value.toString());
      if (isAlreadyInOptions) {
        isDublicateResponse = true;
      }
      unicOptionsValue.current.push(option.value.toString());
    });
    if (!isDublicateResponse) {
      setResponseData(prev => [...prev, ...response.options]);
    }
    setPage(page + 1);
    isFetching.current = false;
  };

  const handleScroll = async (e: MouseEvent<HTMLUListElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLElement;
    const scrollPosition = scrollTop + clientHeight;
    const isCloseToFinish = scrollPosition >= scrollHeight - 100;

    if (isCloseToFinish) {
      const options: FetchGarOptions = {
        regionId,
        districtId,
        cityId,
        streetId,
        page: page,
        limit,
      };
      const isNeedFetching = (page - 1) * limit < totalOptions.current && !isFetching.current;

      switch (requestDataType) {
        case GarRequestsEnum.Regions:
          if (isNeedFetching) {
            isFetching.current = true;
            await getGarRegionsWithLimit(options)
              .then(handleResponseAfterScroll);
          }
          break;
        case GarRequestsEnum.Districts:
          if (isNeedFetching && regionId) {
            isFetching.current = true;
            await getGarDistrictsWithLimit(options)
              .then(handleResponseAfterScroll);
          }
          break;
        case GarRequestsEnum.Cities:
          if (isNeedFetching) {
            isFetching.current = true;
            await getGarCitiesWithLimit(options)
              .then(handleResponseAfterScroll);
          }
          break;
        case GarRequestsEnum.Streets:
          if (isNeedFetching) {
            isFetching.current = true;
            await getGarStreetsWithLimit(options)
              .then(handleResponseAfterScroll);
          }
          break;
        case GarRequestsEnum.Houses:
          if (isNeedFetching) {
            isFetching.current = true;
            await getGarHousesWithLimit(options)
              .then(handleResponseAfterScroll);
          }
          break;
      }
    }
  };

  const { data: countriesData } = useGetCountriesQuery(undefined, {
    skip: requestDataType !== GarRequestsEnum.Countries,
  });

  useEffect(() => {
    if (countriesData) setResponseData(countriesData);
  }, [countriesData]);

  useEffect(() => {
    Initialize();
  }, [regionId, districtId, cityId, streetId]);

  if (requestDataType === GarRequestsEnum.Districts && responseData.length === 0 && !targetInput.current) {
    return null;
  }

  const debouncedHandleInputValue = debounce(handleInputValue, 1000);

  return (
    <>
      <FormLabel required={required}>{label}</FormLabel>
      <Select
        className={clsx(className, mods)}
        disabled={disabled}
        id={id}
        options={responseData}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onScroll={handleScroll}
        onInput={(newValue) => {
          debouncedHandleInputValue(newValue);
          targetInput.current = true;
        }}
      />
      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </>
  );
};
