import { WindowActionTypes } from "../types/windowTypes";
import {
  IAction,
  IFilredMenuFormData,
  IWindowStateQuestionnaire,
  IWindowStateQuestionnaireApplication,
  IWindowTargetAdmission,
} from "@/types/store";
import { Dispatch } from "react";
import windowService from "@/lib/services/window.service";
import userService from "@/lib/services/user.service";
import {
  IWindowAdmissionQuotaValues,
  IWindowCompetitiveGroupScore,
  IWindowDisciplineScore,
  IWindowProgramCost,
} from "@/types/window/forms";
import { IDictionaryItem } from "@/lib/services/dictionary.service";
import { WindowActions } from "@Entities/Window";
import {
  NotificationAction,
  NotificationActionEnum,
} from "@/components/Window/Forms/BaseWindowForm/types/NotificationType";
import { loaderActions } from "@Entities/Loader/model/slice/loaderSlice";

export const setCurrentParams = (payload: IFilredMenuFormData): IAction => {
  return { type: WindowActionTypes.SET_PARAMS, payload: payload };
};

export const setDisciplines = (
  payload: { label: string; value: string }[] | null
): IAction => {
  return { type: WindowActionTypes.SET_DISCIPLINES, payload: payload };
};

export const setSpoDisciplines = (
  payload: { label: string; value: string }[] | null
): IAction => {
  return { type: WindowActionTypes.SET_SPO_DISCIPLINES, payload: payload };
};

export const setForeignerDisciplines = (
  payload: { label: string; value: string }[] | null
): IAction => {
  return {
    type: WindowActionTypes.SET_FOREIGNER_DISCIPLINES,
    payload: payload,
  };
};

export const setTargetAdmissions = (
  payload: { items: IWindowTargetAdmission[]; total: number } | null
): IAction => {
  return { type: WindowActionTypes.SET_TARGET_ADMISSIONS, payload: payload };
};

export const fetchProgramDisciplines = (programId: string) => {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    try {
      const data = await windowService.getDisciplineProgram(programId);
      dispatch(WindowActions.setProgramDisciplines(data as IDictionaryItem[]));
    } catch (err) {
      dispatch(WindowActions.setProgramDisciplines([]));
    }
  };
};

export const fetchForeignerDisciplines = (
  degree: string,
  forUkraines: boolean
) => {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    try {
      const data = await windowService.getFormDisciplines(
        degree,
        false,
        false,
        forUkraines
      );
      await dispatch(
        WindowActions.setForignerDisciplines(
          data.map((item) => {
            return { label: item.label, value: String(item.value) };
          })
        )
      );
    } catch (err) {
      dispatch(WindowActions.setForignerDisciplines([]));
    }
  };
};

export const fetchSpoDisciplines = (degree: string, spo: boolean) => {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    try {
      const data = await windowService.getFormDisciplines(degree, false, spo);
      await dispatch(
        WindowActions.setSpoDisciplines(
          data.map((item) => {
            return { label: item.label, value: String(item.value) };
          })
        )
      );
    } catch (err) {
      dispatch(WindowActions.setSpoDisciplines([]));
    }
  };
};

export const fetchDisciplines = (degree: string) => {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    try {
      const data = await windowService.getFormDisciplines(degree, true);
      const result = data.map((item) => {
        return { label: item.label, value: String(item.value) };
      });

      dispatch(WindowActions.setDisciplines(result));
    } catch (err) {
      dispatch(WindowActions.setDisciplines([]));
    }
  };
};

export const fetchTargetAdmissions = (
  degree: string,
  page: number,
  limit: number,
  sortBy?: "id" | "isConfirmed",
  order?: "ASC" | "DESC"
) => {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    try {
      const result = await windowService.getTargetAdmissions(
        degree,
        page,
        limit,
        sortBy,
        order
      );

      dispatch(WindowActions.setTargetAdmissions(result));
    } catch (err) {
      dispatch(WindowActions.setTargetAdmissions(null));
    }
  };
};

export const fetchSpecialConditions =
  (
    degree: string,
    page?: number,
    limit?: number,
    sortBy?: "special_condition_is_confirmed" | "number",
    order?: "ASC" | "DESC",
    year?: number
  ) =>
    async (dispatch: Dispatch<any>): Promise<void> => {
      dispatch(loaderActions.startLoading());
      const payload = await windowService.getSpecialConditions(
        degree,
        page,
        limit,
        sortBy,
        order,
        year
      );
      dispatch(loaderActions.stopLoading());

      if (payload) {
        dispatch(WindowActions.setSpecialConditions(payload));
      }
    };

export const updateSpecialConditionsStatus =
  (userId: number, studentDetailsId: number, value: boolean) =>
    async (dispatch: Dispatch<any>): Promise<boolean> => {
      const status = await windowService.updateStatusSpecialCondition(
        userId,
        studentDetailsId,
        value
      );
      if (status) {
        dispatch({
          type: WindowActionTypes.UPD_STATUS_SPECIAL_CONDITIONS,
          payload: { userId, studentDetailsId, value },
        });
        return status;
      }
      return status;
    };

type FetchStatistics = (params: {
  degree: any;
  type: string;
}) => (dispatch: Dispatch<any>) => Promise<void>;

export const fetchStatistics: FetchStatistics =
  (params) =>
    async (dispatch: Dispatch<any>): Promise<void> => {
      const { columns, data } = await windowService.getStatisctics(params);

      const formattedData = data.map((item: any) => {
        return [...Object.values(item)];
      });

      const formattedColumns = columns.map((column: any) => {
        return { label: column, name: column, isSortable: false };
      });

      dispatch({
        type: WindowActionTypes.SET_STATISTICS,
        payload: { columns: formattedColumns, data: formattedData },
      });
    };

export const fetchProgramsWithCosts =
  (degree: IDegree, year: number) =>
    async (dispatch: Dispatch<any>): Promise<void> => {
      const programs = await windowService.getProgramsWithCost(degree, year);
      dispatch(WindowActions.setFormsProgramsWithCost(programs));
    };

export const updateProgramsCosts =
  (
    programs: IWindowProgramCost[],
    dispatchNotification: Dispatch<NotificationAction>
  ) =>
    async (dispatch: Dispatch<any>): Promise<boolean> => {
      const status = await windowService.updateProgramsCosts(programs);

      if (status) {
        dispatch({
          type: WindowActionTypes.UPD_FORMS_PROGRAMS_WITH_COST,
          payload: programs,
        });
        dispatchNotification({
          type: NotificationActionEnum.SUCCESS,
        });
      } else {
        dispatchNotification({
          type: NotificationActionEnum.ERROR,
        });
      }
      return status;
    };

export const fetchGDisciplines =
  () =>
    async (dispatch: Dispatch<any>): Promise<void> => {
      const disciplines = await windowService.getDisciplines();
      dispatch(WindowActions.setFormsDisciplines(disciplines));
    };

export const updateDisciplinesScores =
  (disciplineScores: IWindowDisciplineScore[]) =>
    async (dispatch: Dispatch<any>): Promise<boolean> => {
      const status = await windowService.updateDisciplinesScores(
        disciplineScores
      );
      if (status)
        dispatch({
          type: WindowActionTypes.UPD_FORMS_DISCIPLINES,
          payload: disciplineScores,
        });
      return status;
    };

export const fetchCompetitveGroups =
  (year: number, degree?: "bachelor" | "postgraduate") =>
    async (dispatch: Dispatch<any>): Promise<void> => {
      const competitiveGroups = await windowService.getCompetitiveGroups(
        year,
        degree
      );
      dispatch(WindowActions.setFormsCompetitiveGroup(competitiveGroups));
    };

export const updateCompetitveGroups =
  (
    competitiveGroups: IWindowCompetitiveGroupScore[],
    dispatchNotification: Dispatch<NotificationAction>
  ) =>
    async (dispatch: Dispatch<any>): Promise<boolean> => {
      const status = await windowService.updateCompetitiveGroupScores(
        competitiveGroups
      );
      if (status) {
        dispatch({
          type: WindowActionTypes.UPD_FORMS_COMPETITIVE_GROUPS,
          payload: competitiveGroups,
        });
        dispatchNotification({
          type: NotificationActionEnum.SUCCESS,
        });
      } else {
        dispatchNotification({
          type: NotificationActionEnum.ERROR,
        });
      }
      return status;
    };

const MAX_PROGRAM_LEN = 100;
export const fetchAdmissionQuotas =
  (degree?: IDegree, year?: number) =>
    async (dispatch: Dispatch<any>): Promise<void> => {
      const admissionQuotas = await windowService.getProgramsWithAdmissionQuotas(
        degree,
        year,
        MAX_PROGRAM_LEN
      );
      dispatch(WindowActions.setFormsAdmissionQuota(admissionQuotas));
    };

export const updateAdmissionQuotas =
  (
    admissionQuotas: IWindowAdmissionQuotaValues[],
    dispatchNotification: Dispatch<NotificationAction>
  ) =>
    async (dispatch: Dispatch<any>): Promise<boolean> => {
      const status = await windowService.updateAdmissionQuotas(admissionQuotas);
      if (status) {
        dispatch({
          type: WindowActionTypes.UPD_FORMS_ADMISSION_QUOTA,
          payload: admissionQuotas,
        });
        dispatchNotification({
          type: NotificationActionEnum.SUCCESS,
        });
      } else {
        dispatchNotification({
          type: NotificationActionEnum.ERROR,
        });
      }
      return status;
    };

/** Код вынесеный из папки /setver/windowQuestionnaireRequirements, чтобы избавится от лишней папки в корне */

interface WindowQuestionnaireOptions {
  dispatch: Dispatch<any>;
}

export const windowQuestionnaireRequirements = async (
  options: WindowQuestionnaireOptions
): Promise<void> => {
  const { dispatch } = options;

  const searchParams = window.location.pathname.split("/");
  const userId = searchParams[4];
  const studentDetailsId = searchParams[5];

  const parsedUserId = Number.parseInt(String(userId));
  const parsedStudentDetailsId = Number.parseInt(String(studentDetailsId));

  if (
    !Number.isInteger(parsedUserId) ||
    !Number.isInteger(parsedStudentDetailsId)
  )
    return;
  //TODO: delete questionnaireStudentDetailsListData & questionnaireStudentDetailsData
  const questionnaireStudentDetailsListData =
    await userService.getStudentDetails(parsedUserId, true);
  const questionnaireStudentDetailsData =
    questionnaireStudentDetailsListData &&
    questionnaireStudentDetailsListData?.find(
      (item) => item.id === parsedStudentDetailsId
    );

  if (!questionnaireStudentDetailsData) return;

  const questionnaireUserPersonalityData = await userService.getPersonally(
    parsedUserId,
    parsedStudentDetailsId
  );

  const questionnaireUserData: IUserData = {
    id: parsedUserId,
    first_name: questionnaireUserPersonalityData.fullName.firstName,
    last_name: questionnaireUserPersonalityData.fullName.lastName,
    middle_name: questionnaireUserPersonalityData.fullName.middleName,
    email: questionnaireUserPersonalityData.contact.email,
    phone: questionnaireUserPersonalityData.contact.phone,
    citizenship: questionnaireUserPersonalityData.citizenship,
    student_details: questionnaireStudentDetailsData.id,
    degree: questionnaireStudentDetailsData.degree,
    isSendOriginalUpdatedAt: null,
    roles: [],
    windows: [],
    manager_degrees: [],
  };

  let questionnaireApplication: IWindowStateQuestionnaireApplication | null =
    null;
  questionnaireApplication = await windowService.getUserApplication(
    questionnaireUserData.id,
    questionnaireStudentDetailsData.id
  );

  const questionnaire: IWindowStateQuestionnaire = {
    user: questionnaireUserData,
    studentDetails: questionnaireStudentDetailsData,
    application: questionnaireApplication,
    isEditableMode: true,
  };

  dispatch(WindowActions.setWindowQuestionary(questionnaire));
};
