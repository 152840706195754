import UserRoleEnum from "@/lib/enums/userRole";
import { useTypedSelector } from "@/hooks/useTypedSelector";

const useRoles = (): {
  isUserSuperAdmin: boolean;
  isUserManager: boolean;
  isUserClient: boolean;

  isWindowBachelor: boolean;
  isWindowMaster: boolean;
  isWindowPostgraduate: boolean;
  isWindowForeigner: boolean;
  isWindowManagerPostgraduate: boolean;
} => {
  const { user } = useTypedSelector((state) => state.user);

  const { active: activeWindow } = useTypedSelector((state) => state?.window);

  const isUserSuperAdmin = user.roles.includes(UserRoleEnum.ROLE_SUPER_ADMIN);
  const isUserManager =
    activeWindow?.role === UserRoleEnum.ROLE_MANAGER || false;
  const isUserClient = !isUserSuperAdmin && !isUserManager && !activeWindow;

  const isWindowManagerPostgraduate = user?.roles.includes(
    UserRoleEnum.ROLE_WINDOW_POSTGRADUATE ?? false
  );

  const isWindowBachelor =
    activeWindow?.role === UserRoleEnum.ROLE_WINDOW_BACHELOR || false;
  const isWindowMaster =
    activeWindow?.role === UserRoleEnum.ROLE_WINDOW_MASTER || false;
  const isWindowPostgraduate =
    activeWindow?.role === UserRoleEnum.ROLE_WINDOW_POSTGRADUATE || false;
  const isWindowForeigner =
    activeWindow?.role === UserRoleEnum.ROLE_WINDOW_FOREIGNER || false;

  return {
    isUserSuperAdmin,
    isUserManager,
    isUserClient,

    isWindowBachelor,
    isWindowMaster,
    isWindowPostgraduate,
    isWindowForeigner,
    isWindowManagerPostgraduate,
  };
};

export default useRoles;
