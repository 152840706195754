import DegreeEnum from "./enums/degree";
import { windowsEnum } from "./enums/userRole";

export const getAvailableDegreesByWindow = (
  windows: {
    id: number;
    title: string;
    role: windowsEnum;
  }[]
): DegreeEnum[] => {
  if (
    windows.length === 1 &&
    windows.some((e) => e.role === windowsEnum.ROLE_WINDOW_FOREIGNER)
  ) {
    return [DegreeEnum.BACHELOR, DegreeEnum.MASTER];
  }

  return windows
    .map(
      ({ role }) =>
        ({
          [windowsEnum.ROLE_WINDOW_BACHELOR]: DegreeEnum.BACHELOR,
          [windowsEnum.ROLE_WINDOW_MASTER]: DegreeEnum.MASTER,
          [windowsEnum.ROLE_WINDOW_POSTGRADUATE]: DegreeEnum.POSTGRADUATE,
          [windowsEnum.ROLE_WINDOW_FOREIGNER]: undefined,
        }[role])
    )
    .filter((item) => !!item);
};
