import { FC } from "react";
import styles from "./Checkbox.module.scss";
import clsx from "clsx";
import ActionIcon from "@/components/_Common/ActionIcon";
import HelpIcon from "@/public/images/symbols/hint.svg";
import useTranslation from "next-translate/useTranslation";

interface CheckboxProps {
    id?: string
    value: boolean
    onChange(value: boolean): void
    label: string
    hint?: boolean
    disabled?: boolean
}

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { id, label, value, onChange, hint, disabled } = props;
  const { t } = useTranslation("personal/education");

  const handleChangeValue = () => {
    if (!disabled) {
      onChange(!value);
    }
  };

  const controlMods: Record<string, boolean> = {
    [styles.checkbox__control_active]: value,
  };

  return (
    <div
      className={styles.checkbox}
      id={`${id}_wrapper`}
      onClick={handleChangeValue}
    >
      <input
        checked={value}
        disabled={disabled}
        id={id}
        type="hidden"
      />
      <span className={clsx(styles.checkbox__control, controlMods)} id={`${id}_сheckboxStatus_${!!value}`} />
      <div>
        <span className={styles.checkbox__text}>{label}</span>
        {hint &&
          <ActionIcon hint Icon={HelpIcon} placement="top">
            {t("hint.three")}
          </ActionIcon>
        }
      </div>
    </div>
  );
};
