import { ThunkApiType } from "@App/providers/StoreProvider";
import { createAsyncThunk } from "@reduxjs/toolkit";
import camelcaseKeys from "camelcase-keys";
import { FormActions } from "../../slice/FormSlice";
import getErrorMessage from "@/lib/_common/getErrorMessage";

interface ChosenProgramsType {
    userId: number
    studentDetailsId: number
}

export const setChosenPrograms = createAsyncThunk<ISendProgram[], ChosenProgramsType, ThunkApiType<string>>(
  "form/setChosenPrograms",
  async (options, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;
    const { userId, studentDetailsId } = options;

    try {
      const response = await extra.api.get(`/users/${userId}/studentDetails/${studentDetailsId}/programs`);
      const payload = camelcaseKeys(response.data.result.selected_programs, { deep: true }).sort((a: any, b: any) => a.priority - b.priority);


      dispatch(FormActions.setChosenPrograms(payload));
      return response.data;
    } catch ({ response: error }: any) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
