import styles from "@/components/_Common/Form/Checkbox/Checkbox.module.scss";
import { Form } from "react-bootstrap";
import CheckIcon from "@/public/images/symbols/checkbox.svg";
import { FC, PropsWithChildren, ReactNode, ChangeEvent } from "react";
import clsx from "clsx";
import ActionIcon from "@/components/_Common/ActionIcon";
import HelpIcon from "@/public/images/symbols/hint.svg";
import useTranslation from "next-translate/useTranslation";
import { UseFormRegister } from "react-hook-form";

type CheckboxProps = {
  children: ReactNode;
  id: string | undefined;
  name: string;
  register?: UseFormRegister<any>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  additionalStyles?: string;
  defaultChecked?: boolean | null;
  hint?: boolean;
  type?: "data" | "education" | "achievements";
  position?: "one" | "two" | "three";
  mode?: string;
  disabled?: boolean;
  checked?: boolean;
  extraChecked?: boolean;
};

const FormCheckbox: FC<PropsWithChildren<CheckboxProps>> = (
  props
): JSX.Element => {
  const {
    children,
    id,
    name,
    register,
    checked,
    onChange,
    additionalStyles,
    hint,
    type,
    position,
    mode,
    disabled,
    defaultChecked,
    extraChecked,
  } = props;
  const { t } = useTranslation(`personal/${type}`);

  return (
    <div id={`${id}_wrapper`}>
      <Form.Check
        className={clsx(styles.checkBox, additionalStyles)}
        disabled={true}
        type="checkbox"
      >
        <input
          {...register?.(name)}
          checked={checked ?? extraChecked}
          defaultChecked={defaultChecked as boolean}
          disabled={disabled}
          id={id}
          type="checkbox"
          className={clsx(
            styles.checkBox__input,
            mode && styles.checkBox__input_dark
          )}
          onChange={onChange}
        />
        <Form.Check.Label className={clsx(styles.checkBox__label)} htmlFor={id} id={`${id}_label`}>
          <span
            className={clsx(styles.checkBox__icon, styles.checkBox__icon_dark)}
            id={`${id}_сheckboxStatus_${!!checked || !!defaultChecked || !!extraChecked}`}
          >
            <CheckIcon className={styles.checkBox__svg} />
          </span>
          <span>{children}</span>
          {hint && (
            <ActionIcon hint Icon={HelpIcon} placement="top">
              {position ? t(`hint.${position}`) : t("hint")}
            </ActionIcon>
          )}
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};

export default FormCheckbox;
